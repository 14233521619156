<template>
    <aside class="sidebar-light-primary a-sidebar a-sidebar-filter"
        :class="a_class"
        :style="a_style"
        @mouseover="$emit('mouseover')"
        @mouseleave="$emit('mouseleave')"
    >
        <h3 class="a-sidebar-header pl-4 pr-4 pt-4 pb-2 d-inline-block">
            Filters
        </h3>

        <template v-if="$route.path.includes('admin')">
            <div class="sidebar" style="padding-bottom: 80px;">
                <template v-if="['adminreasons', 'adminsellerlistings','admindummies'].includes($route.name)">
                    <template v-if="!['admindummies'].includes($route.name)">
                        <h4 
                            id="filter-research-status"
                            style="cursor:pointer;"
                            class="a-sidebar-filter-header pl-4 pr-4 pt-2"
                            data-toggle="collapse"
                            data-target="#collapseStatus"
                            title="Click to show/hide research statuses">
                            Research Status <i class="fas fa-chevron-down ml-2"></i>
                        </h4>

                        <nav class="mt-2 collapse show" id="collapseStatus" aria-labelledby="filter-research-status">
                            <div style="min-height: 100px">
                                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                                    data-accordion="false">
                                    <li class="nav-item a-nav-filter-item" v-for="(status, index) in admin_statuses" :key="index">
                                        <div class="custom-control custom-radio">
                                            <input class="custom-control-input" type="radio" :id="`status-${status.id}`"
                                                name="status" :value="status.id" :checked="$route.query.status == status.id" @change="$router.push({'query': Object.assign({},$route.query,{'status':status.id})})">
                                            <label :for="`status-${status.id}`" class="custom-control-label">{{status.status == "Recheck" ? "For Rework" : status.status }}</label>
                                        </div>
                                    </li>           
                                </ul>
                            </div>
                        </nav>
                    </template>

                    <template v-if="!['adminreasons'].includes($route.name)">
                        <h4 
                            class="a-sidebar-filter-header pt-3 pr-4 pl-4 pb-1"
                            style="cursor:pointer;"
                            :style="`${$route.name != 'admindummies' ? 'border-top: 1px solid #eee' : ''}`"
                            id="filter-platform"
                            data-toggle="collapse"
                            data-target="#collapsePlatform"
                            title="Click to show/hide Platforms">
                            Platforms <i class="fas fa-chevron-down ml-2"></i>
                        </h4>
                        <nav class="mt-2 collapse" id="collapsePlatform" aria-labelledby="filter-research-platform">
                            <div style="min-height: 100px">
                                <div v-if="!['admindummies'].includes($route.name)">
                                    <ar-textfield
                                        icon="fas fa-search"
                                        :class="`ml-3`"
                                        placeholder="Filter platforms"
                                        v-model="searched_admin_platform"
                                    />
                                </div>
                                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                                    <template v-if="!['admindummies'].includes($route.name)">
                                        <li class="nav-item a-nav-filter-item" v-for="(platform,index) in filtered_admin_platforms" :key="index">
                                            <div class="custom-control " 
                                                :class="{'custom-radio':['adminsellers'].includes($route.name),'custom-checkbox':false}"
                                            >
                                                <input class="custom-control-input" 
                                                    :type="['adminsellers'].includes($route.name) ? 'radio' : 'checkbox'" 
                                                    name="platform" 
                                                    :id="`platform-${platform.value}`" 
                                                    :value="platform.value"
                                                    :checked="$route.query.platform == platform.value"
                                                    @change="$router.push({'query': Object.assign({},$route.query,{platform:platform.value})})">
                                                <label :for="`platform-${platform.value}`" class="custom-control-label">{{platform.label}}</label>
                                            </div>
                                            
                                        </li>
                                    </template>
                                    <!-- Show Radio button instead of checkbox in Dummy Account Management-->
                                    <template v-else>
                                        <li class="nav-item a-nav-filter-item">
                                            <div class="custom-control custom-radio">
                                                <input class="custom-control-input" type="radio" :id="`platform-all`"
                                                    name="platform" value="all" :checked="$route.query.platform == 'all'" @change="$router.push({'query': Object.assign({},$route.query,{platform:'all'})})">
                                                <label :for="`platform-all`" class="custom-control-label">All</label>
                                            </div>
                                        </li>

                                        <li class="nav-item a-nav-filter-item" v-for="(platform,index) in admin_radio_platforms" :key="index">
                                            <div class="custom-control custom-radio">
                                                <input class="custom-control-input" type="radio" :id="`platform-${platform}`"
                                                    name="platform" :value="platform" :checked="platform.toLowerCase().includes($route.query.platform.toLowerCase())" @change="$router.push({'query': Object.assign({},$route.query,{platform})})">
                                                <label :for="`platform-${platform}`" class="custom-control-label">{{platform}}</label>
                                            </div>
                                        </li>
                                    </template>
                                </ul>
                            </div>
                        </nav>
                    </template>
                    <!--
                        Added Filter By filter here since I can't use the Filter by filter below becuase of condition
                        -Dave 4.24.2020
                    -->
                    <template v-if="['admindummies'].includes($route.name)">
                        <h4 
                            id="filter-sort-by"
                            :style="`cursor:pointer; ${$route.name == 'adminartemisusers' ? 'border-top: 1px solid #eee' : ''}`"
                            class="a-sidebar-filter-header pl-4 pr-4 pt-2"
                            data-toggle="collapse"
                            data-target="#collapseSort"
                            title="Click to show/hide sort by">
                            Filter By <i class="fas fa-chevron-down ml-2"></i>
                        </h4>

                        <nav class="mt-2 collapse show" id="collapseSort" aria-labelledby="filter-sort-by">
                            <div style="min-height: 100px">
                                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                                    data-accordion="false">
                                    
                                    <li class="nav-item a-nav-filter-item">
                                        <div class="custom-control custom-radio">
                                            <input class="custom-control-input" type="radio" id="sort-all"
                                                name="filterby" value="all" @change="$router.push({'query': Object.assign({},$route.query,{'status':'all'})})" :checked="$route.query.status === 'all'">
                                            <label for="sort-all" class="custom-control-label">All</label>
                                        </div>
                                    </li>
                                    <li class="nav-item a-nav-filter-item">
                                        <div class="custom-control custom-radio">
                                            <input class="custom-control-input" type="radio" id="sort-active"
                                                name="filterby" value="active" @change="$router.push({'query': Object.assign({},$route.query,{'status':'active'})})" :checked="$route.query.status === 'active'">
                                            <label for="sort-active" class="custom-control-label">Active</label>
                                        </div>
                                    </li>
                                    <li class="nav-item a-nav-filter-item">
                                        <div class="custom-control custom-radio">
                                            <input class="custom-control-input" type="radio" id="sort-inactive"
                                                name="filterby" value="inactive" @change="$router.push({'query': Object.assign({},$route.query,{'status':'inactive'})})" :checked="$route.query.status === 'inactive'">
                                            <label for="sort-inactive" class="custom-control-label">Inactive</label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </template>

                    <template v-if="['admindummies'].includes($route.name)">
                        <h4 
                            id="filter-sort-by"
                            :style="`cursor:pointer; ${$route.name == 'adminartemisusers' ? 'border-top: 1px solid #eee' : ''}`"
                            class="a-sidebar-filter-header pl-4 pr-4 pt-2"
                            data-toggle="collapse"
                            data-target="#collapseOrder"
                            title="Click to show/hide sort by">
                            Order By <i class="fas fa-chevron-down ml-2"></i>
                        </h4>

                        <nav class="mt-2 collapse show" id="collapseOrder" aria-labelledby="filter-sort-by">
                            <div style="min-height: 100px">
                                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                                    data-accordion="false">
                                 
                                    <li class="nav-item a-nav-filter-item">
                                        <div class="custom-control custom-radio">
                                            <input class="custom-control-input" type="radio" id="order-asc"
                                                name="orderby" value="asc" @change="$router.push({'query': Object.assign({},$route.query,{'order':'asc'})})" :checked="$route.query.order === 'asc'">
                                            <label for="order-asc" class="custom-control-label">Ascending</label>
                                        </div>
                                    </li>
                                    <li class="nav-item a-nav-filter-item">
                                        <div class="custom-control custom-radio">
                                            <input class="custom-control-input" type="radio" id="order-desc"
                                                name="orderby" value="desc" @change="$router.push({'query': Object.assign({},$route.query,{'order':'desc'})})" :checked="$route.query.order === 'desc'">
                                            <label for="order-desc" class="custom-control-label">Descending</label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </template>
                </template>

                <template v-else>
                    <template v-if="['adminartemisusers', 'adminenvaccessmanagement'].includes($route.name)">
                        <h4 
                            class="a-sidebar-filter-header pt-3 pr-4 pl-4 pb-1"
                            style="cursor:pointer;"
                            id="filter-user-types"
                            data-toggle="collapse"
                            data-target="#collapseUserTypes"
                            title="Click to show/hide user types">
                            User Type <i class="fas fa-chevron-down ml-2"></i>
                        </h4>

                        <nav class="mt-2 collapse show" id="collapseUserTypes" aria-labelledby="filter-user-types">
                            <div style="min-height: 100px">
                                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                                    data-accordion="false">
                                    <li class="nav-item a-nav-filter-item" v-for="(type,index) in admin_user_types" :key="index">
                                        <div class="custom-control custom-radio">
                                            <input @change="changeFilterOrOrder(type.value, 'type')" class="custom-control-input" type="radio" :id="`user-type-${type.value}`" name="usertypes" :value="type.value" :checked="$route.query.type == type.value || (type.value === -1 && $route.query.type === undefined)" />
                                            <label :for="`user-type-${type.value}`" class="custom-control-label">{{type.label}}</label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </template>

                    <template v-if="$route.name === 'adminenvaccessmanagement'">
                        <h4 
                            id="filter-environment"
                            :style="`cursor:pointer; ${$route.name == 'adminartemisusers' ? 'border-top: 1px solid #eee' : ''}`"
                            class="a-sidebar-filter-header pl-4 pr-4 pt-2"
                            data-toggle="collapse"
                            data-target="#collapseEnvironment"
                            title="Click to show/hide environment">
                            Environment <i class="fas fa-chevron-down ml-2"></i>
                        </h4>

                        <nav class="mt-2 collapse show" id="collapseEnvironment" aria-labelledby="filter-environment">
                            <div style="min-height: 100px">
                                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                                    data-accordion="false">
                                    <li class="nav-item a-nav-filter-item">
                                        <div class="custom-control custom-radio">
                                            <input class="custom-control-input" type="radio" id="env-all"
                                                name="env" value="all" @change="changeFilterOrOrder(-1, 'env')" :checked="$route.query.env == -1 || $route.query.env === undefined">
                                            <label for="env-all" class="custom-control-label">All</label>
                                        </div>
                                    </li>
                                    <li class="nav-item a-nav-filter-item">
                                        <div class="custom-control custom-radio">
                                            <input class="custom-control-input" type="radio" id="env-beta"
                                                name="envs" value="beta" @change="changeFilterOrOrder('beta', 'env')" :checked="$route.query.env == 'beta'">
                                            <label for="env-beta" class="custom-control-label">Beta</label>
                                        </div>
                                    </li>
                                    <li class="nav-item a-nav-filter-item">
                                        <div class="custom-control custom-radio">
                                            <input class="custom-control-input" type="radio" id="env-stage"
                                                name="envs" value="stage" @change="changeFilterOrOrder('stage', 'env')" :checked="$route.query.env == 'stage'">
                                            <label for="env-stage" class="custom-control-label">Stage</label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </template>

                    <template v-if="$route.name == 'adminsellers'">
                        <h4 
                            id="filter-counsel"
                            style="cursor:pointer; border-top: 1px solid #eee"
                            class="a-sidebar-filter-header pt-3 pr-4 pl-4 pb-1"
                        >
                        Show Whitelisted Sellers
                        </h4>

                        <nav class="mt-2 collapse show" id="collapseCounsel" aria-labelledby="filter-counsel">
                            <div style="min-height: 100px">
                                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                                    data-accordion="false">
                                    <li class="nav-item a-nav-filter-item" >
                                        <div class="custom-control custom-checkbox">
                                            <input
                                                v-model ="show_whitelist"
                                                id ="show-whitelist-sellers"
                                                class ="custom-control-input"
                                                type ="checkbox"
                                                name ="counsel"
                                            >
                                            <label for="show-whitelist-sellers" class="custom-control-label">Whitelist Sellers</label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </template>

                    <template v-if="excludedRoutes">
                        <h4 
                            id="filter-sort-by"
                            :style="`cursor:pointer; ${$route.name == 'adminartemisusers' ? 'border-top: 1px solid #eee' : ''}`"
                            class="a-sidebar-filter-header pl-4 pr-4 pt-2"
                            data-toggle="collapse"
                            data-target="#collapseSort"
                            title="Click to show/hide sort by">
                            Filter By <i class="fas fa-chevron-down ml-2"></i>
                        </h4>

                        <nav class="mt-2 collapse show" id="collapseSort" aria-labelledby="filter-sort-by">
                            <div style="min-height: 100px">
                                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                                    data-accordion="false">
                                    <li class="nav-item a-nav-filter-item">
                                        <div class="custom-control custom-radio">
                                            <input class="custom-control-input" type="radio" id="sort-all"
                                                name="filterby" value="all" @change="changeFilterOrOrder(-1, 'status')" :checked="$route.query.status == -1">
                                            <label for="sort-all" class="custom-control-label">All</label>
                                        </div>
                                    </li>
                                    <li class="nav-item a-nav-filter-item">
                                        <div class="custom-control custom-radio">
                                            <input class="custom-control-input" type="radio" id="sort-active"
                                                name="filterby" value="active" @change="changeFilterOrOrder(1, 'status')" :checked="$route.query.status == 1 || $route.query.status === undefined">
                                            <label for="sort-active" class="custom-control-label">Active</label>
                                        </div>
                                    </li>
                                    <li class="nav-item a-nav-filter-item">
                                        <div class="custom-control custom-radio">
                                            <input class="custom-control-input" type="radio" id="sort-inactive"
                                                name="filterby" value="inactive" @change="changeFilterOrOrder(0, 'status')" :checked="$route.query.status == 0">
                                            <label for="sort-inactive" class="custom-control-label">Inactive</label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </template>

                    <template v-if="$route.name == 'adminweeklyreport'">
                        <h4 
                            id="filter-sort-by"
                            style="cursor:pointer"
                            class="a-sidebar-filter-header pl-4 pr-4 pt-2"
                            data-toggle="collapse"
                            data-target="#collapseSort"
                            title="Click to show/hide sort by">
                            Sort By <i class="fas fa-chevron-down ml-2"></i>
                        </h4>

                        <nav class="mt-2 collapse show" id="collapseSort" aria-labelledby="filter-sort-by">
                            <div style="min-height: 100px">
                                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                                    data-accordion="false">
                                    <li class="nav-item a-nav-filter-item">
                                        <div class="custom-control custom-radio">
                                            <input class="custom-control-input" type="radio" id="sort-all"
                                                name="filterby" value="all" @change="changeFilterOrOrder('name', 'sort')" :checked="$route.query.sort == 'name' || $route.query.status === undefined">
                                            <label for="sort-all" class="custom-control-label">Name</label>
                                        </div>
                                    </li>
                                    <li class="nav-item a-nav-filter-item">
                                        <div class="custom-control custom-radio">
                                            <input class="custom-control-input" type="radio" id="sort-active"
                                                name="filterby" value="active" @change="changeFilterOrOrder('access_level', 'sort')" :checked="$route.query.sort == 'access_level'">
                                            <label for="sort-active" class="custom-control-label">Access Level</label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </template>

                    <h4 
                        id="filter-order-by"
                        style="cursor:pointer;"
                        class="a-sidebar-filter-header pl-4 pr-4 pt-2"
                        data-toggle="collapse"
                        data-target="#collapseOrder"
                        title="Click to show/hide order by">
                        Order By <i class="fas fa-chevron-down ml-2"></i>
                    </h4>

                    <nav class="mt-2 collapse show" id="collapseOrder" aria-labelledby="filter-order-by">
                        <div style="min-height: 100px">
                            <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                                data-accordion="false">
                                <li class="nav-item a-nav-filter-item">
                                    <div class="custom-control custom-radio">
                                        <input class="custom-control-input" type="radio" id="order-asc"
                                            name="orderby" value="asc" @change="changeFilterOrOrder('asc', 'order')" :checked="$route.query.order === 'asc' || $route.query.order === undefined">
                                        <label for="order-asc" class="custom-control-label">Ascending</label>
                                    </div>
                                </li>
                                <li class="nav-item a-nav-filter-item">
                                    <div class="custom-control custom-radio">
                                        <input class="custom-control-input" type="radio" id="order-desc"
                                            name="orderby" value="desc" @change="changeFilterOrOrder('desc', 'order')" :checked="$route.query.order === 'desc'">
                                        <label for="order-desc" class="custom-control-label">Descending</label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </template>
            </div>
        </template>
        <template v-else>
            <!-- v-if="filters.status === 'Cases' && ($route.params.case_id != '0' && typeof $route.params.case_id !== 'undefined')"> -->
            <ar-button 
                @click="applyCaseFilters" 
                class="position-relative d-inline-block font-weight-normal" 
                style="margin-left: 100px; font-size: 12px; top: -5px" 
                title="Apply specified filters"
                v-if="$route.params.status === 'Cases' && ($route.params.case_id != '0' && typeof $route.params.case_id !== 'undefined')">
                Apply
            </ar-button>
            
            <div class="sidebar">
                <template v-if="$route.name == 'lawfirmcounterfeitchecker'">
                    <h4 
                        id="filter-sort-by"
                        style="cursor:pointer;"
                        class="a-sidebar-filter-header pl-4 pr-4 pt-2"
                        data-toggle="collapse"
                        data-target="#collapseSort"
                        title="Click to show/hide sort by">
                        Sort By <i class="fas fa-chevron-down ml-2"></i>
                    </h4>

                    <nav class="mt-2 collapse show" id="collapseSort" aria-labelledby="filter-sort-by">
                        <div style="min-height: 100px">
                            <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                                data-accordion="false">
                                <li class="nav-item a-nav-filter-item">
                                    <div class="custom-control custom-radio">
                                        <input class="custom-control-input" type="radio" id="sort-all"
                                            name="filterby" value="all" @change="changeFilterOrOrder(-1, 'status')" :checked="$route.query.status == -1">
                                        <label for="sort-all" class="custom-control-label">All</label>
                                    </div>
                                </li>
                                <li class="nav-item a-nav-filter-item">
                                    <div class="custom-control custom-radio">
                                        <input class="custom-control-input" type="radio" id="sort-active"
                                            name="filterby" value="active" @change="changeFilterOrOrder(1, 'status')" :checked="$route.query.status == 1 || $route.query.status === undefined">
                                        <label for="sort-active" class="custom-control-label">Active</label>
                                    </div>
                                </li>
                                <li class="nav-item a-nav-filter-item">
                                    <div class="custom-control custom-radio">
                                        <input class="custom-control-input" type="radio" id="sort-inactive"
                                            name="filterby" value="inactive" @change="changeFilterOrOrder(0, 'status')" :checked="$route.query.status == 0">
                                        <label for="sort-inactive" class="custom-control-label">Inactive</label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </nav>

                    <h4 
                        id="filter-order-by"
                        style="cursor:pointer;"
                        class="a-sidebar-filter-header pl-4 pr-4 pt-2"
                        data-toggle="collapse"
                        data-target="#collapseOrder"
                        title="Click to show/hide order by">
                        Order By <i class="fas fa-chevron-down ml-2"></i>
                    </h4>

                    <nav class="mt-2 collapse show" id="collapseOrder" aria-labelledby="filter-order-by">
                        <div style="min-height: 100px">
                            <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                                data-accordion="false">
                                <li class="nav-item a-nav-filter-item">
                                    <div class="custom-control custom-radio">
                                        <input class="custom-control-input" type="radio" id="order-asc"
                                            name="orderby" value="asc" @change="changeFilterOrOrder('asc', 'order')" :checked="$route.query.order === 'asc' || $route.query.order === undefined">
                                        <label for="order-asc" class="custom-control-label">Ascending</label>
                                    </div>
                                </li>
                                <li class="nav-item a-nav-filter-item">
                                    <div class="custom-control custom-radio">
                                        <input class="custom-control-input" type="radio" id="order-desc"
                                            name="orderby" value="desc" @change="changeFilterOrOrder('desc', 'order')" :checked="$route.query.order === 'desc'">
                                        <label for="order-desc" class="custom-control-label">Descending</label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </template>
                <template v-else>
                    <!-- <template v-if="filters.status === 'Cases' && ($route.params.case_id != '0' && typeof $route.params.case_id !== 'undefined')">  -->
                    <template v-if="$route.params.status === 'Cases' && ($route.params.case_id != '0' && typeof $route.params.case_id !== 'undefined')">
                        <h4 
                            id="filter-seller-flags"
                            style="cursor:pointer;"
                            class="a-sidebar-filter-header pl-4 pr-4 pt-2"
                            data-toggle="collapse"
                            data-target="#collapseSellerFlags"
                            title="Click to show/hide seller flags">
                            Seller Flags <i class="fas fa-chevron-down ml-2"></i>
                        </h4>

                        <nav class="mt-2 collapse show" id="collapseSellerFlags" aria-labelledby="filter-seller-flags">
                            <div style="min-height: 100px">
                                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                                    data-accordion="false">
                                    <!-- <li class="nav-item a-nav-filter-item">
                                        <div class="custom-control custom-checkbox">
                                            <input :value="All" class="custom-control-input" type="checkbox" id="diha1" name="seller_flag" v-model="filters.seller_flags" checked>
                                            <label for="diha1" class="custom-control-label">All</label>
                                        </div>
                                    </li> -->
                                    <li class="nav-item a-nav-filter-item" v-for="(seller_flag,index) in seller_flags" :key="index">
                                        <div class="custom-control custom-checkbox">
                                            <input class="custom-control-input" type="checkbox" name="seller_flag" :id="seller_flag" v-model="selected_seller_flags" :value="seller_flag">
                                            <label :for="seller_flag" class="custom-control-label">{{seller_flag}}</label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </nav>

                        
                        <template v-if="counsels.length > 0">
                            <h4 
                                id="filter-counsel"
                                style="cursor:pointer; border-top: 1px solid #eee"
                                class="a-sidebar-filter-header pt-3 pr-4 pl-4 pb-1"
                                data-toggle="collapse"
                                data-target="#collapseCounsel"
                                title="Click to show/hide counsel">
                                Counsel <i class="fas fa-chevron-down ml-2"></i>
                            </h4>
                            <nav class="mt-2 collapse show" id="collapseCounsel" aria-labelledby="filter-counsel">
                                <div style="min-height: 100px">
                                    <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                                        data-accordion="false">
                                        <li class="nav-item a-nav-filter-item" v-for="(counsel,index) in counsels" :key="index">
                                            <div class="custom-control custom-checkbox">
                                                <input class="custom-control-input" type="checkbox" name="counsel" :id="counsel" v-model="selected_counsels" :value="counsel">
                                                <label :for="counsel" class="custom-control-label">{{counsel}}</label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </template>

                        <h4 
                            id="filter-payment-methods"
                            style="cursor:pointer; border-top: 1px solid #eee"
                            class="a-sidebar-filter-header pt-3 pr-4 pl-4 pb-1"
                            data-toggle="collapse"
                            data-target="#collapsePaymentMethods"
                            title="Click to show/hide payment methods">
                            Payment Methods <i class="fas fa-chevron-down ml-2"></i>
                        </h4>
                        <nav class="mt-2 collapse show" id="collapsePaymentMethods" aria-labelledby="filter-payment-methods">
                            <div style="min-height: 100px">
                                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                                    data-accordion="false">
                                    <li class="nav-item a-nav-filter-item" v-for="(method,index) in payment_methods" :key="index">
                                        <div class="custom-control custom-checkbox">
                                            <input class="custom-control-input" type="checkbox" name="method" :id="method.value" v-model="selected_payment_methods" :value="method.label">
                                            <label :for="method.value" class="custom-control-label">{{method.label}}</label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                        
                    </template>
            
                    <template v-else>
                        <ul v-if="show_error" class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                            data-accordion="false">
                            <li class="nav-item a-nav-filter-item">
                                <div class="custom-control custom-checkbox">
                                    <input :checked="show_error" id="show_error" @click="changeShowError" class="custom-control-input" type="checkbox" name="show_error" />
                                    <label for="show_error" class="custom-control-label">Show error(s) only</label>
                                </div>
                            </li>
                        </ul>
                        <nav class="mt-2" v-if="$route.path.includes('verification')">
                            <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                                data-accordion="false">
                                <template v-if="access_level() == 7">
                                    <li class="nav-item a-nav-filter-item">
                                        <div class="custom-control custom-checkbox">
                                            <input class="custom-control-input" type="checkbox" name="data-shown" id="my-qa-data" v-model="shown_data" value="QD">
                                            <label for="my-qa-data" class="custom-control-label">My QA Data</label>
                                        </div>
                                    </li>
                                    <li class="nav-item a-nav-filter-item">
                                        <div class="custom-control custom-checkbox">
                                            <input class="custom-control-input" type="checkbox" name="data-shown" id="my-research-data" v-model="shown_data" value="RD">
                                            <label for="my-research-data" class="custom-control-label">My Research Data</label>
                                        </div>
                                    </li>
                                </template>
                                <template v-else-if="access_level() == 2">
                                    <li class="nav-item a-nav-filter-item">
                                        <div class="custom-control custom-checkbox">
                                            <input class="custom-control-input" type="checkbox" name="data-shown" id="my-own-data" v-model="shown_data" value="QD">
                                            <label for="my-own-data" class="custom-control-label">My Data</label>
                                        </div>
                                    </li>
                                </template>
                            </ul>
                        </nav>
                        
                        <h4 
                            id="filter-research-status"
                            style="cursor:pointer;"
                            class="a-sidebar-filter-header pl-4 pr-4 pt-2"
                            data-toggle="collapse"
                            data-target="#collapseStatus"
                            title="Click to show/hide research statuses">
                            Research Status <i class="fas fa-chevron-down ml-2"></i>
                        </h4>

                        <nav class="mt-2 collapse show" id="collapseStatus" aria-labelledby="filter-research-status">
                            <div style="min-height: 100px">
                                <template v-if="$route.path.includes('lawfirm')">
                                    <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                                        data-accordion="false">
                                        <li class="nav-item a-nav-filter-item" v-for="(status, index) in dem_statuses" :key="index">
                                            <div class="custom-control custom-radio"  @click="lawfirmChangeStatus(status.label)">
                                                <input class="custom-control-input" type="radio" :id="`status-${status.label != null ? status.label.replace(/\s/g, '-') : ''}`"
                                                    name="statuses" :value="status.label" :checked="urldecode($route.params.status) == status.label">
                                                <label :for="`status-${status.label != null ? status.label.replace(/\s/g, '-') : ''}`" class="custom-control-label">{{status.label}} {{ status.val !== "" ? `(${status.val})` : '' }}</label>
                                            </div>
                                        </li>
                                    </ul>
                                </template>
                                <template v-else-if="$route.path.includes('verification/search')">
                                    <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                                        data-accordion="false">
                                        <li class="nav-item a-nav-filter-item" v-for="(status, index) in dem_statuses" :key="index">
                                            <div class="custom-control custom-radio">
                                                <input class="custom-control-input" type="radio" :id="`status-${status}`"
                                                    name="status" :value="status" :checked="status == $route.query.status" @change="changeRadio(status)">
                                                <label :for="`status-${status}`" class="custom-control-label">{{status == "Recheck" ? "For Rework" : status }}</label>
                                            </div>
                                        </li>
                                    </ul>
                                </template>
                                <template v-else-if="$route.path.includes('verification')">
                                    <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                                        data-accordion="false">
                                        <li class="nav-item a-nav-filter-item" v-for="(status, index) in dem_statuses" :key="index">
                                            <div class="custom-control custom-radio">
                                                <input class="custom-control-input" type="radio" :id="`status-${status.label != null ? status.label.replace(/\s/g, '-') : ''}`"
                                                    name="status" :value="status.label" :checked="status.label == $route.query.status" @change="changeRadio(status.label)">
                                                <label :for="`status-${status.label != null ? status.label.replace(/\s/g, '-') : ''}`" class="custom-control-label">
                                                {{status.label == "Recheck" ? "For Rework" : status.label == 'Hacked' ? 'Auto Qualified' : status.label }} ({{status.val}})
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </template>
                            </div>
                        </nav>

                        <template v-if="filters.status === 'Cases' && ($route.params.case_id == 'undefined' || $route.params.case_id == 0)">
                            <div class="cases-button animated bounceInRight">
                                <ar-button icon="fas fa-plus" :is_fab="true" data-toggle="modal" data-target="#new-case-modal">Create a New Case</ar-button>
                            </div>

                        </template>

                        <template v-else-if="$route.path.includes('lawfirm')==false">

                            <h4 
                                class="a-sidebar-filter-header pt-3 pr-4 pl-4 pb-1"
                                style="cursor:pointer; border-top: 1px solid #eee"
                                id="filter-platform"
                                data-toggle="collapse"
                                data-target="#collapsePlatform"
                                title="Click to show/hide platforms">
                                Platforms <i class="fas fa-chevron-down ml-2"></i>
                            </h4>

                            <nav class="mt-2 collapse show" id="collapsePlatform" aria-labelledby="filter-research-platform">
                                <div style="min-height: 100px">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <ar-textfield
                                            icon="fas fa-search"
                                            class="ml-3 mb-0"
                                            a_style="width: 193px;"
                                            placeholder="Filter platforms"
                                            v-model="filter_platform"
                                        />
                                        <div>
                                            <ar-button @click="applyPlatformFilters" class="d-inline-block mr-2" style="font-size: 12px; font-weight: 300" title="Apply specified platforms filter on search">Apply</ar-button>
                                        </div>
                                    </div>
                                    <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                                        data-accordion="false">
                                        <li class="nav-item a-nav-filter-item">
                                            <div class="custom-control custom-checkbox">
                                                <input value="0" class="custom-control-input" type="checkbox" id="All Platforms" name="platforms"  @change="changeCheckbox({name: 'All', value: 0})" :checked="selected_checkbox_count == 0" @click="uncheckPlatforms">
                                                <label for="All Platforms" class="custom-control-label">All Platforms</label>
                                            </div>
                                        </li>
                                        <li class="nav-item a-nav-filter-item" v-for="(platform,index) in dem_platforms" :key="index">
                                            <div class="custom-control custom-checkbox">
                                                <input class="custom-control-input" type="checkbox" name="platforms" :id="platform.name" :value="platform.value" @change="changeCheckbox(platform)">
                                                <label :for="platform.name" class="custom-control-label">{{platform.name}}</label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </template>
                    </template>

                    <template v-if="$route.path.includes('lawfirm') && !(filters.status === 'Cases' && ($route.params.case_id == 'undefined' || $route.params.case_id == 0))">
                        <h4 
                            class="a-sidebar-filter-header pt-3 pr-4 pl-4 pb-1"
                            style="cursor:pointer; border-top: 1px solid #eee"
                            id="filter-platform"
                            data-toggle="collapse"
                            data-target="#collapsePlatform"
                            title="Click to show/hide platforms">
                            Platforms <i class="fas fa-chevron-down ml-2"></i>
                        </h4>

                        <nav class="mt-2 collapse show" id="collapsePlatform" aria-labelledby="filter-research-platform">
                            <div style="min-height: 100px">
                                <div class="mb-3" :class="{'d-flex align-items-center justify-content-between': filters.status != 'Cases'}">
                                    <ar-textfield
                                        icon="fas fa-search"
                                        :class="`ml-3 mb-0`"
                                        a_style="width: 193px;"
                                        placeholder="Filter platforms"
                                        v-model="filter_platform"
                                    />
                                    <div>
                                        <ar-button v-if="filters.status != 'Cases'" @click="applyPlatformFilters" class="d-inline-block mr-2" style="font-size: 12px; font-weight: 300" title="Apply specified platforms filter on search">Apply</ar-button>
                                    </div>
                                </div>
                                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                                    data-accordion="false">
                                    <li class="nav-item a-nav-filter-item" v-for="(platform,index) in lf_platforms" :key="index">
                                        <div class="custom-control custom-checkbox">
                                            <input class="custom-control-input" type="checkbox" name="platform" :id="`platform-${platform.value}`" v-model="selected_lf_platforms" :value="platform.value">
                                            <label :for="`platform-${platform.value}`" class="custom-control-label">{{platform.label}}</label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </template>
                </template>
            </div>
        </template>
    </aside>
</template>

<script>
import button from './Button'
import textfield from './Textfield'
import axios from '../../axiosMime'
import {mapActions, mapState} from 'vuex'
export default {
    name:"ar-filter-drawer",
    components: {
            "ar-button": button,
            "ar-textfield": textfield
    },
    props:{
        value:{
            type:String,
            default:''
        },
        statuses: {
            type: Array,
            default: () => []
        },
        platforms: {
            type: Array,
            default:() => []
        },
        a_class: {
            type: String,
            default: ''
        },
        a_style: {
            type: String,
            default: ''
        }
    },
    beforeCreate() {
        if(location.pathname.includes("lawfirm") && (this.$route.params.status != null && typeof this.$route.params.status == 'undefined')){
            this.status = this.$route.params.status
            this.filters.status = this.$route.params.status
        }
    },
    data() {
        return {
            filters: {
                status : typeof this.$route.params.status === 'undefined' ? '' : this.$route.params.status,
                selected_seller_flags : [],
                selected_counsels: [],
                selected_payment_methods: [],
                selected_lf_platforms : [],
                platforms: []
            },
            selected_checkbox_count: 0,
            selected_checkbox: [],
            status : '',
            seller_flags : [],
            counsels: [],
            payment_methods: [],
            lf_platforms : [],
            dem_statuses: [],
            dem_platforms: [],
            filter_platform: "",
            joined_platforms: "",
            selected_lf_platforms: [],
            selected_seller_flags : [],
            selected_counsels: [],
            selected_payment_methods: [],
            shown_data: [],
            /**
             * Deliverable: Hide all For Rework/Reworked elements across all GUI
             * Deliverable Number: 454
             */
            admin_statuses: [{id:4,status:"Work in Progress"}, {id:1,status:"Researched"}/*,{id:2,status:"For Rework"},{id:8,status:"Reworked"} Hide For Rework / Reworked */,{id:3,status:"Qualified"},{id:98,status:"Has Potential"},{id:99,status:"Invalid"},{id:33,status:"Rejected"}],
            active_status:4, //clicked admin status will store here
            searched_admin_platform:"",
            admin_platforms: [],
            admin_radio_platforms:[],
            searched_admin_campaign:"",
            admin_campaigns:[],
            admin_user_types: [{
                value: -1,
                label: "All"
            },
            {
                value: 1,
                label: "Researcher"
            },
            {
                value: 2,
                label: "QA"
            },
            {
                value: 3,
                label: "Lawyer"
            },
            {
                value: 4,
                label: "Administrator"
            },
            {
                value: 5,
                label: "Campaign Lead"
            },
            {
                value: 6,
                label: "Client"
            },
            {
                value: 7,
                label: "Super Admin"
            }],
            show_whitelist: false
        }
    },
    computed: {
        ...mapState(['show_error', 'feature_access']),
        listing_page() {
            return this.$route.path.includes('search') ? 'search' : 'listing'
        },
        filtered_admin_campaigns(){
            let campaigns = this.admin_campaigns
            let searched  = this.searched_admin_campaign.trim()
            if(searched.trim() !== ""){
                return campaigns = campaigns.filter(campaign => campaign.name.toLowerCase().includes(searched.toLowerCase()))
            }
            return campaigns
        },
        filtered_admin_platforms(){//value / label
            let platforms = this.admin_platforms
            let searched  = this.searched_admin_platform.trim()
            if(searched.trim() !== ""){
                return platforms = platforms.filter(platform => platform.label.toLowerCase().includes(searched.toLowerCase()))
            }
            return platforms
        },
        excludedRoutes() {
            return !['adminsellers', 'adminweeklyreport'].includes(this.$route.name)
        }
        // access_level2() {
        //     let access_level = 3
        //     if(location.pathname.includes("verification") && localStorage.getItem("ver") != null){
        //         return JSON.parse(localStorage.getItem("ver")).access_level
        //     }
        //     return access_level
        // },
        // status_header() {
        //     if ((this.access_level() == 1 || this.access_level() == 5) && !this.$route.path.includes('verification/search'))
        //         return "Campaign Status"
        //     else if ([2].includes(this.access_level()) && !this.$route.path.includes('verification/search'))
        //         return "QA Status"
        //     else if ([3].includes(this.access_level()))
        //         return "Research Status"
        //     else
        //         return "Statuses"
        // }
    },
    created(){
        if (this.$route.path.includes('lawfirm')) {
            this.setFilters(this.filters)
            this.getCounsels()

            if (this.$route.params.campaign_id != null) {
                this.getLfPlatforms()
                this.getPaymentTypes()
            }
        }

        this.initializeStatuses()
    },
    mounted(){
        if(this.$route.path.includes('lawfirm')){
            this.status = this.$route.params.status
            this.filters.status = this.$route.params.status
            this.getSellerFlags()
        } 
        
        if (this.$route.path.includes('verification') && ((JSON.parse(localStorage.getItem('ver')) || {}).token || null) != null){
            this.getPlatforms()
        }

        if(this.$session.has("admin")){
            if(['admindummies'].includes(this.$route.name)){
                this.getAdminDummyPlatforms()
            }
        }
        
    },
    methods: {
        ...mapActions(['setFilters', 'updateShown', 'changeShowError', 'checkFeatureAccess', 'cancelRequests', 'refreshCancelToken']),
        changeSellerFlag(){
            // console.log('hey')
        },
        changeFilterOrOrder(val, type) {
            let query = {...this.$route.query};
            query[type] = val;
            this.$router.push({name: this.$route.name, query: query});
        },
        getPaymentTypes(){
            let header = JSON.parse(localStorage.getItem('headers'))
            axios.get(`${process.env.VUE_APP_URL}/payment_types/in/campaign/${this.$route.params.campaign_id}/post-filed?page_size=10000`, header)
                .then(response => {
                    this.payment_methods = []
                    for(let x of response.data.data){
                        this.payment_methods.push({
                            value: x.id,
                            label : x.payment_name    
                        })
                    }
                }
            )
        },
        getLfPlatforms(){
            let header = JSON.parse(localStorage.getItem('headers'))
            header.headers["Case-id"] = this.$route.params.case_id
            header.headers.Sort = "priority,DESC,name,ASC"

            if(this.$route.path.includes('campaign')){
                axios.get(`${process.env.VUE_APP_URL}/platforms/in/campaign/${this.$route.params.campaign_id}?page_size=1000`, 
                                        header
                                        ).then(
                                            response => {
                                            this.lf_platforms = []
                                            for(let x of response.data.data){
                                                this.lf_platforms.push({
                                                    value : x.id,
                                                    label : x.name
                                                })
                                            }
                                        }
                                    )
            }
        },
        getCounsels(){
            let header = JSON.parse(localStorage.getItem('headers'))
            // header.headers.where = `{"case_id": { "listings" : { "campaign_id" : ${this.$route.params.campaign_id} } }}`
            header.headers.where = `{"case_id": ${this.$route.params.case_id} }`
            axios.get(`${process.env.VUE_APP_URL}/case_listings/multi`, 
                        header
                        ).then(
                            response => {
                            this.counsels = []
                            for(let x of response.data.data){
                                if(!this.counsels.includes(x.counsel) && x.counsel != null && x.counsel != ""){
                                    this.counsels.push(x.counsel)
                                }
                            }
                        }
                    )
        },
        changeRadio(status) {
            this.$router.push({name: this.listing_page, query: {status: status, platform: this.$route.query.platform}})
        },
        changeCheckbox(platform) {
            let checked = document.querySelectorAll('input[name=platforms]:checked')
            var platforms = []
            this.filters.platforms = []

            this.selected_checkbox_count = 0

            for (var i = 0; i < checked.length; i++) {
                if (checked[i].value == '0') continue
                this.selected_checkbox_count++
                this.selected_checkbox.push({ id: checked[i].value, name: checked[i].innerHTML})
                platforms.push(checked[i].value)
                this.filters.platforms.push(checked[i].value)
            }

            if(location.pathname.includes("lawfirm")){
                // commented para sa button nalang ang pagset sa platfor filter, hehehe - Marvin 1/28/20
                // this.setFilters(this.filters)
            }else{
                // this.$router.push({name: this.listing_page, params: {campaign_id: this.$route.params.campaign_id}, query: {status: this.$route.query.status, platform: platforms.join() || '0' }})
                // para sa button nalang, hehehe
                this.joined_platforms = platforms.join() || '0'
            }
        },
        uncheckPlatforms() {
            let platforms = document.querySelectorAll('input[name=platforms]')
            this.selected_checkbox_count = 0

            for (var i = 0; i < platforms.length; i++) {
                if (platforms[i].value == 0) {
                    platforms[i].checked = true
                    continue
                }

                platforms[i].checked = false
            }

            if(location.pathname.includes("lawfirm")){
                // commented para sa button nalang ang pagset sa platfor filter, hehehe - Marvin 1/28/20
                // this.setFilters(this.filters)
            } else if (this.$route.path.includes('verification')) {
                if (this.$route.name == "listing" || this.$route.name == "search") {
                    // this.$router.push({name: this.listing_page, query: {status: this.$route.query.status, platform: 0 }})
                    // para mo-effect nalang inig click sa apply. gomenasai :)
                    this.joined_platforms = '0'
                }
            }
        },
        disablePlatforms(status = true) {
            let platforms = document.querySelectorAll('input[name=platforms]')

            for (var i = 0; i < platforms.length; i++) {
                if (platforms[i].value == 0) continue

                if (status) platforms[i].attr("disabled", "disabled")
                else platforms[i].removeAttr("disabled")
            }
        },
        getQaStatuses(){
            let header = JSON.parse(JSON.parse(localStorage.getItem("ver")).headers)
            axios.get(process.env.VUE_APP_URL+`/qflag?page_size=20`,header)
                .then(response=>{
                    if(response.data.meta.code == 200){
                        let status = response.data.data.filter(status=>{return [1,2,3,8,31,32,33,34,98,99].includes(status.id) }).map(status=>{return status.label})

                        var sortingArr = ["Recheck", "Reworked", "Researched", "Qualified", "Monitoring", "Rejected", "Accepted", "Filed", "Has Potential", "Invalid"]

                        status = status.sort(function(a, b){  
                            return sortingArr.indexOf(a) - sortingArr.indexOf(b);
                        });

                        this.dem_statuses = status
                    }
                })
        },
        getAllStatuses() {
            let header = JSON.parse(JSON.parse(localStorage.getItem("ver")).headers)
            axios.get(process.env.VUE_APP_URL+`/qflag?page_size=100`,header)
                .then(response=>{
                    if(response.data.meta.code == 200){
                        let status = response.data.data.filter(status => {return ![0,5,6,7,9,10,97].includes(status.id)}).map(status=>{return status.label })
                        status.unshift("All")
                        this.dem_statuses = status
                    }
                })
        },
        initializeStatuses() {            
            if (this.$route.params.campaign_id != null && this.$route.name != "search" && this.$route.path.includes("verification")) {
                
                let headers = JSON.parse(JSON.parse(localStorage.getItem(this.$route.path.includes('lawfirm') ? "lawfirm" : "ver")).headers)

                headers.headers.Where = JSON.stringify({id: this.$route.params.campaign_id})

                axios.get(process.env.VUE_APP_URL+`/my/campaigns`, headers)
                .then(response=>{
                    let campaign = response.data.data[0]
                    let obj

                    if([1,5,6].includes(this.access_level())){
                        obj = {
                            "Work in Progress" : campaign.total_items_by_status_and_user['work in progress'],
                            "Recheck"          : campaign.total_items_by_status_and_user.recheck,
                            "Reworked"         : campaign.total_items_by_status_and_user.reworked,
                            "Researched"       : campaign.total_items_by_status_and_user.researched,
                            "Qualified"        : campaign.total_items_by_status_and_user.qualified,
                            "Monitoring"       : campaign.total_items_by_status_and_user.monitoring,
                            "Accepted"         : campaign.total_items_by_status_and_user.accepted,
                            "Filed"            : campaign.total_items_by_status_and_user.filed,
                            "Invalid"          : campaign.total_items_by_status_and_user.invalid,
                            "Has Potential"    : campaign.total_items_by_status_and_user["has potential"]
                        }
                    } else if ([2,7].includes(this.access_level())) {
                        obj = {
                            "Work in Progress" : campaign.total_items_by_status["work in progress"],
                            "Recheck"          : campaign.total_items_by_status.recheck,
                            "Reworked"         : campaign.total_items_by_status.reworked,
                            "Researched"       : campaign.total_items_by_status.researched,
                            "Qualified"        : campaign.total_items_by_status.qualified,
                            "Hacked"           : campaign.total_items_by_status.hacked,
                            "Monitoring"       : campaign.total_items_by_status.monitoring,
                            "Rejected"         : campaign.total_items_by_status.rejected,
                            "Accepted"         : campaign.total_items_by_status.accepted,
                            "Submitted"        : campaign.total_items_by_status.submitted,
                            "Filed"            : campaign.total_items_by_status.filed,
                            "Has Potential"    : campaign.total_items_by_status["has potential"],
                            "Invalid"          : campaign.total_items_by_status.invalid
                        }
                        // console.log(obj)
                        if(this.access_level() == 2){
                            delete obj["Work in Progress"]
                            delete obj["Hacked"]
                        }
                    }

                    this.dem_statuses = Object.entries(obj).map(([key, val]) => { return {label: key, val: val}})
                }).catch(err=>{alert(err.response.data.meta.message+"Please contact system admin.")})
            } else if (this.$route.path.includes("lawfirm") && this.$route.params.campaign_id != null) {
                let headers = JSON.parse(JSON.parse(localStorage.getItem("lawfirm")).headers)

                headers.headers.Where = JSON.stringify({id: this.$route.params.campaign_id})

                axios.get(process.env.VUE_APP_URL+`/lawfirm_campaigns?page_size=1000`, headers)
                .then(response=>{
                    let campaign = response.data.data[0]
                    let obj

                    obj = {
                        "Has Potential"    : campaign.total_items_by_status["has potential"],
                        "For Review"       : campaign.total_items_by_status.qualified,
                        "Monitoring"       : campaign.total_items_by_status.monitoring,
                        "Accepted"         : campaign.total_items_by_status.accepted,
                        "Submitted"        : campaign.total_items_by_status.submitted,
                        "Rejected"         : campaign.total_items_by_status.rejected,
                        // "Auto Qualified"   : campaign.total_items_by_status.hacked
                    }

                    if(this.access_level() == 7 || this.hasCaseStatusAccess()) { //added by Joe (enable Cases if user is level7); // hasCaseStatusAccess() added by Marvin
                        obj["For Internal Review"] = campaign.total_items_by_status["for internal review"]
                        obj["Cases"] = campaign.total_items_by_status.cases
                    }

                    this.dem_statuses = Object.entries(obj).map(([key, val]) => { return {label: key, val: val}})
                }).catch(err=>{alert(err.response.data.meta.message+"Please contact system admin.")})
            } else if (this.$route.name == "search") {
                this.getAllStatuses()
            }
        },
        access_level() {
            let access_level = 3
            if(location.pathname.includes("verification") && localStorage.getItem("ver") != null){
                return JSON.parse(localStorage.getItem("ver")).access_level
            } else if (location.pathname.includes("lawfirm") && localStorage.getItem("lawfirm") != null) {
                return JSON.parse(localStorage.getItem("lawfirm")).access_level
            }
            return access_level
        },
        hasCaseStatusAccess() {
            if (this.access_level() == 7)
                return true;

            return this.feature_access != 0;
        },
        filterPlatforms() {
            if (this.$route.path.includes('verification')) {
                let platforms = document.querySelectorAll('input[name=platforms]')
                let keyword = this.filter_platform == "" ? "" : this.filter_platform.toLowerCase().trim()
                
                for (var index = 0; index < platforms.length; index++) {
                    if (platforms[index].id == "All Platforms") 
                        continue
                    else if (!platforms[index].id.toLowerCase().trim().includes(keyword)) {
                        platforms[index].parentElement.parentElement.style.visibility = "hidden"
                        platforms[index].parentElement.parentElement.style.padding = "0"
                        platforms[index].parentElement.parentElement.style.height = "0"
                    } else {
                        platforms[index].parentElement.parentElement.style.visibility = ""
                        platforms[index].parentElement.parentElement.style.padding = ""
                        platforms[index].parentElement.parentElement.style.height = ""
                    }
                }
            } else {
                let platforms = document.querySelectorAll('input[name=platform]')
                let keyword2 = this.filter_platform == "" ? "" : this.filter_platform.toLowerCase().trim()

                for (var index2 = 0; index2 < platforms.length; index2++) {
                    if (!platforms[index2].parentElement.innerHTML.trim().toLowerCase().includes(keyword2)) {
                        platforms[index2].parentElement.parentElement.style.visibility = "hidden"
                        platforms[index2].parentElement.parentElement.style.padding = "0"
                        platforms[index2].parentElement.parentElement.style.height = "0"
                    } else {
                        platforms[index2].parentElement.parentElement.style.visibility = ""
                        platforms[index2].parentElement.parentElement.style.padding = ""
                        platforms[index2].parentElement.parentElement.style.height = ""
                    }
                }
            }
        },
        getPlatforms() {
            let headers = JSON.parse(JSON.parse(localStorage.getItem('ver')).headers)

            headers.headers.Sort = "priority,DESC,name,ASC"
            
            if (this.$route.params.campaign_id != null) {
                axios.get(`${process.env.VUE_APP_URL}/platforms/in/campaign/${this.$route.params.campaign_id}?page_size=1000`, headers)
                    .then(response => {
                        let results = response.data
                        this.dem_platforms = results.data.filter(platform => platform.name != 'Others').map(platform=>{ return {value:platform.id,name:platform.name} })
                    }
                )
            } else {
                headers.headers.Where = JSON.stringify({status: 1})
                axios.get(`${process.env.VUE_APP_URL}/platforms?page_size=1000`, headers)
                    .then(response => {
                        let results = response.data
                        this.dem_platforms = results.data.map(platform=> { return {value:platform.id,name:platform.name} })
                    }
                )
            }
        },
        applyPlatformFilters() {
            if(location.pathname.includes("lawfirm")){
                // assign the scoped selected_lf_platforms to the filter object's selected_lf_platforms
                this.filters.selected_lf_platforms = this.selected_lf_platforms
            } else if (this.$route.path.includes('verification')) {
                if (this.$route.name == "listing" || this.$route.name == "search") {
                    if (this.$route.query.keywords != null)
                        this.$router.push({name: this.listing_page, params: {campaign_id: this.$route.params.campaign_id}, query: {status: this.$route.query.status, platform: this.joined_platforms, keywords: this.$route.query.keywords }})
                    else
                        this.$router.push({name: this.listing_page, params: {campaign_id: this.$route.params.campaign_id}, query: {status: this.$route.query.status, platform: this.joined_platforms }})
                }
            }
        },
        applyCaseFilters() {
            this.filters = {
                selected_lf_platforms: this.selected_lf_platforms,
                selected_counsels: this.selected_counsels,
                selected_payment_methods: this.selected_payment_methods,
                selected_seller_flags: this.selected_seller_flags
            }
        },
        isPlatformSelected(id) {
            return (this.$route.query.platform || '').split(',').includes(id)
        },
        getSellerFlags() {
            let headers = JSON.parse(localStorage.getItem('headers'))
            if (headers != null) {
                axios.get(`${process.env.VUE_APP_URL}/case_flags`, 
                    headers
                    ).then(
                        async response => {
                        this.seller_flags = response.data.data.filter(flag => flag.status == 1).map(flag => flag.name)
                        let initial = JSON.stringify(this.seller_flags)
                        this.seller_flags = this.seller_flags.filter(s => !s.toLowerCase().includes("default"))
    
                        if (await this.checkFeatureAccess([4]))
                            this.seller_flags = JSON.parse(initial)
                    }
                )
                .catch(err => {
                    console.log(err)
                    this.$parent.showSnackbar('Something went wrong while getting Seller Flag. Plase contact system admin.', false, 'invalid', 'fas fa-times-circle')
                })
            }
        },
         getAdminDummyPlatforms(){//Platforms fetched are based on the listed platforms in Dummy Accounts
            const headers = this.$session.get("admin_headers")
            this.$http.get("dummy_accounts/platforms",headers)
             .then(response=>{
                 if(response.status == 200){
                     let platforms = response.data.data.map(platform=> platform.platform_name.split(".")[0].toLowerCase().replace(/\s/g, "")).filter(f=> f !== "")
                     this.admin_radio_platforms = platforms.filter((item, index) => platforms.indexOf(item) === index).map(platform=> platform.charAt(0).toUpperCase() + platform.slice(1)).sort()
                 }
             })
             .catch(err=>{
                 console.log(err)
                 this.$parent.showSnackbar("Something went wrong while fetching Dummy Platforms. Please contact system admin.",false, "invalid", "fas fa-times-circle")
             })
         },
         getAdminPlatforms(){
             const headers = this.$session.get("admin_headers")
             this.$http.get("platforms?page_size=1000", headers)
             .then(response=>{
                 if(response.status == 200){
                     let result = response.data
                     this.admin_platforms = result.data.map(platform=>({"value":platform.id,"label":platform.name}))
                 }
             })
             .catch(err=>{
                console.log(err)
                this.$parent.showSnackbar("Something went wrong. Please contact system admin.",false, "invalid", "fas fa-times-circle")
             })
         },
         getAdminCampaigns(){
             const headers = this.$session.get("admin_headers")
             this.$http.get('seller_campaigns?page_size=1000', headers)
             .then(response=>{
                if(response.status == 200){
                    let result = response.data
                    this.admin_campaigns = result.data.map(campaign => ({"id":campaign.id,"name":campaign.name}))
                }
             })
             .catch(err=>{
                 console.log(err)
                 this.$parent.showSnackbar("Something went wrong. Please contact system admin",false,"invalid","fas fa-times-circle")
             })
         },
         lawfirmChangeStatus(status) {
            this.cancelOngoing();
            this.$router.push({name:"lawfirmlisting",
                params:{
                    status      : status,
                    case_id     : this.$route.params.case_id,
                    // seller_flags: this.filters.seller_flags,
                    campaign_id : this.$route.params.campaign_id,
                }
            })
            this.filters.status = status
         },
         urldecode(url) {
            return decodeURIComponent((url || "").replace(/\+/g, ' '));
        },
        cancelOngoing() {
            this.cancelRequests();
            this.refreshCancelToken();
        }
    },
    watch: {
        '$route.name': {
            handler() {
                // this.uncheckPlatforms()
                this.initializeStatuses()
                this.seller_flags = []
                this.payment_methods = []

                if (this.$route.path.includes('verification') && ((JSON.parse(localStorage.getItem('ver')) || {}).token || null) != null){
                    this.getPlatforms()
                }
                
                if(this.$route.name == "lawfirmlisting"){
                    this.status = this.$route.params.status
                    this.filters.status = this.$route.params.status
                    this.setFilters(this.filters)
                    
                    if (this.$route.params.campaign_id != null) {
                        this.getCounsels()
                        this.getLfPlatforms()
                        this.getPaymentTypes()
                    }

                    if (this.$route.params.campaign_id != 0 && this.$route.params.case_id != 0) {
                        this.getSellerFlags()
                    }
                }
                if(this.$session.has("admin")){
                    if(this.$route.name.includes('admindummies')){
                        this.getAdminDummyPlatforms()
                    }else if(['adminreasons'].includes(this.$route.name)){
                        if(this.$route.query.status == null)
                        this.$router.push({"query":Object.assign({},this.$route.query,{"status":this.admin_statuses[0].id})})
                    }
                }
               
            },
            deep:true
        },
        '$route.params.campaign_id'() {
            this.uncheckPlatforms()
            this.initializeStatuses()

            if (this.$route.path.includes('verification') && ((JSON.parse(localStorage.getItem('ver')) || {}).token || null) != null){
                this.getPlatforms()
            }
        },
        filter_platform() {
            this.filterPlatforms()
        },
        shown_data(val) {
            this.updateShown(val)
        },
        active_status(){//push clicked admin status in route
            this.$store.dispatch("setAdmFdStatus",{"status":this.active_status})
            this.$router.push({query:{"status":this.active_status}})
        },
        admin_campaigns(){
            if(this.admin_campaigns.length > 0 && this.$route.query.campaign == null){
                this.$router.push({"query": Object.assign({},this.$route.query,{"campaign":this.admin_campaigns[0].id})})
            }
        },
        admin_platforms(){
            if(this.admin_platforms.length > 0 && this.$route.query.platform == null){
                this.$router.push({"query":Object.assign({},this.$route.query,{"platform":this.admin_platforms[0].value})})
            }
        },
        'filters': {
            handler(val){
                if(this.$route.name == 'lawfirmlisting' && typeof this.$route.params.campaign_id != 'undefined'){
                    this.setFilters(val)
                }
            },
            deep: true
        },
        '$route.query.whitelist'(val) {
            if(val === undefined)
                this.show_whitelist = false
        },
        show_whitelist(val) {
            if(this.$route.name === 'adminsellers') {
                this.$router.push({
                    name: 'adminsellers',
                    query: Object.assign({}, this.$route.query, {whitelist: +val})
                })
            }
        }
    },
}
</script>

<style scoped>
/*
*   SIDEBAR
*/
.a-sidebar {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 6px #00000017;
    height: 100vh;
    text-align: left;
    position: fixed;
    width:256px;
    opacity: 1;
}

.a-sidebar .brand-text {
    letter-spacing: 3.6px;
    color: #3E88DA;
}

.a-sidebar .brand-link .brand-image {
    margin-left: initial;
}

.a-sidebar .sidebar {
    padding:0;
    overflow-y: hidden;
}

.a-sidebar .nav-item .nav-link {
    border-left: 5px solid transparent;
    transition: color 0.2s ease;
}

.a-sidebar .nav-item:hover .nav-link {
    background: #EAF0FCFC !important;
    color: #3E88DA !important;
    border-color: #0F9AEE;
}

.a-sidebar .nav-item .nav-icon {
    margin-right: 13px;
}

.a-sidebar .nav-item:hover .nav-icon {
    color: #0F9AEE;
}

.a-sidebar .nav-link {
    padding-bottom: 13px;
    padding-top: 13px;
    color: #383A3D;
    border-radius: 0;
    font-weight: 500;
    font-size: 16px;
}

.a-sidebar .a-nav-sub .nav-link {
    padding-left: 60px;
    font-weight: 400;
    font-size: 14px;
}

.a-sidebar-header {
    color: #515365;
    font-weight: 500;
    font-size: 24px;
}

.a-sidebar-filter {
    -webkit-transition: top .5s ease, margin .5s ease;
    transition: top .5s ease, margin .5s ease;
    box-shadow: none;
    width: 323px;
    top: 57px;
    right: 0;
}

/* .a-sidebar.a-sidebar-filter {
    padding-bottom: 60px;
} */

.a-sidebar-filter-header {
    text-transform: uppercase;
    font-weight: 500;
    color: #515365;
    font-size: 12px;
}

.a-nav-filter-item {
    padding-bottom: 11px;
    padding-left: 39px;
    padding-top: 11px;
    text-align: left;
    font-weight: 400;
    font-size: 14px;
}

.custom-control label.custom-control-label {
    color: #515365;
    font-weight: 400;
    font-size: 14px;
}

.cases-button {
    position: fixed;
    bottom: 40px;
    right: 100px;
}

/*
*   END OF SIDEBAR
*/
</style>