<template>
    <div 
        class="card a-card" 
        :class="{'a-card-sm': card_type == 'sm', 'a-card-smd': card_type == 'smd', 'a-card-invalid':cardState == 'invalid'}" 
        :id="id"
        @mouseenter="has_hovered_options ? isHovered() : null"
        @mouseleave="has_hovered_options ? resetHovered() : null"
    >
        <template v-if="card_type == 'smd'">
            <div class="a-card-header">
                <a href="javascript:void(0);" @click="$emit('click')">
                    <img :src="logo" :alt="campaign" v-if="has_image">
                    <h4 class="card-lawfirm" v-if="card_info.sub_heading">{{ card_info.sub_heading }}</h4>
                    <h5 class="card-title">{{ card_info.heading }}</h5>
                </a>
                <p class="card-text line-clamp ellipsify">{{ card_info.details }}</p>
                <p class="card-another-text">{{ card_info.extra }}</p>
                <a v-if="has_flag" :title="flag_message" @click="$emit('toggle-click')" @mouseenter="resetHovered()" class="a-card-flag" :data-id="id" role="button">
                    <i class="fas fa-shield-alt"></i>
                </a>
                <a v-if="has_toggle" @click="$emit('toggle-click')" @mouseenter="resetHovered()" class="a-card-toggle" :data-id="id" role="button">
                    <i class="fas fa-chevron-circle-down"></i>
                </a>
                <div v-if="has_toggle" class="card-body" @mouseenter="resetHovered()">
                    <hr/>
                    <div v-if="defaultToggle">
                        <slot></slot>
                    </div>
                    <div v-else class="table-con">
                        <table class="table table-borderless">
                            <tbody v-if="((card_info || {}).sub_info || []).length">
                                <tr v-for="(info, index) in card_info.sub_info" :key="index">
                                    <td 
                                        class="header-label" 
                                        :id="`sub-info-${card_info.heading.replace(/\s/g, '')}-${info.title.replace(/\s/g, '')}`"
                                    >
                                            <img :src="info.image" alt="icon" class="mr-2">{{ info.title }}</td>
                                    <td v-if="info.value"><b>{{ info.value }}</b></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="hovered-options" :class="{'hovered': hovered}">
                <div class="hovered-option-con">
                    <slot name="hovered-options"></slot>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="a-card-header">
                <a href="javascript:void(0);" @click="$emit('click')">
                    <img :src="logo" :alt="campaign"> 
                    <h4 class="card-lawfirm">{{ lawfirm }}</h4>
                    <h5 class="card-title">{{ campaign }}</h5>
                </a>
                <template v-if="this.$route.path.includes('lawfirm')">
                    <p class="card-text">Show Listings Breakdown</p>
                </template>
                <template v-else>
                    <p class="card-text">{{ total_product }} Total Number of Listing(s)</p>
                </template>
                <a @click="$emit('toggle-click')" @mouseenter="resetHovered()" class="a-card-toggle" :data-id="id" role="button">
                    <i class="fas fa-chevron-circle-down"></i>
                </a>
                <div class="card-body" @mouseenter="resetHovered()">
                    <hr/>
                    <table class="table table-borderless">
                        <tbody v-if="!$route.path.includes('lawfirm')">
                            <!-- <tr>
                                <td class="header-label">Open:</td>
                                <td>{{ total_open }} Products</td>
                            </tr> -->
                            <tr>
                                <td class="header-label">WIP:</td>
                                <td>{{ total_wip }} Listing(s)</td>
                            </tr>
                            <tr>
                                <td class="header-label">Rework:</td>
                                <td>{{ total_rework }} Listing(s)</td>
                            </tr>
                            <tr>
                                <td class="header-label">Researched:</td>
                                <td>{{ total_researched }} Listing(s)</td>
                            </tr>
                            <tr>
                                <td class="header-label">Qualified:</td>
                                <td>{{ total_qualified }} Listing(s)</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr v-for="(total,index) in total_products" :key="index">
                                <td class="header-label">{{ index }}:</td>
                                <td>{{ total }} Listing(s)</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="hovered-options" :class="{'hovered': hovered}">
                <div class="hovered-option-con">
                    <slot name="hovered-options"></slot>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import Masonry from 'masonry-layout'
export default {
    name: 'ar-expandable',
    props: {
        logo: {
            type: String,
            default: '/static/svg/Campaign Default Photo.svg'
        },
        lawfirm: {
            type: String,
            default: ''
        },
        campaign: {
            type: String,
            default: ''
        },
        total_product: {
            type: Number,
            default: 0
        },
        total_open: {
            type: Number,
            default: 0
        },
        total_rework: {
            type: Number,
            default: 0
        },
        total_researched: {
            type: Number,
            default: 0
        },
        total_qualified: {
            type: Number,
            default: 0
        },
        total_wip: {
            type: Number,
            default: 0
        },
        id: {
            default:''
        },
        total_products: {
            value : Number,
            default : 0
        },
        card_type: {
            type: String,
            default: 'sm'
        },
        has_image: {
            type: Boolean,
            default: true
        },
        card_info: {
            type: Object,
            default: () => ({})
        },
        has_hovered_options: {
            type: Boolean,
            default: false
        },
        has_toggle: {
            type: Boolean,
            default: true
        },
        cardState:{
            type:String,
            default:""
        },
        has_flag: {
            type: Boolean,
            default: false
        },
        flag_message: {
            type: String,
            default: ''
        },
        defaultToggle: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            hovered_timer: 0,
            hovered: false
        }
    },
    methods: {
        isHovered() {
            this.hovered_timer = setTimeout(() => {
                this.hovered = true
            }, 400)
        },
        resetHovered() {
            clearTimeout(this.hovered_timer)
            this.hovered = false
        }
    }
}
</script>

<style scoped>
    .a-card {
        background        : #FFFFFF 0% 0% no-repeat padding-box;
        -webkit-transition: background .2s ease, height .2s ease, border .5s ease;
        box-shadow        : 0px 1px 3px #00000033;
        transition        : background .2s ease, height .2s ease, border .5s ease;
        border            : 1px solid transparent;
        position          : relative;
        z-index           : 1;
        border-radius     : 4px;
        text-align        : left;
        cursor            : pointer;
        height            : 419px;
        width             : 24%;
        max-width         : 320px;
    }

    .a-card:hover {
        background: #F5F5F5;
    }

    .a-card .a-card-header {
        position: relative;
        display : block;
        height  : 72px;
        padding : 16px 16px 16px 72px;
    }

    .a-card .a-card-header h5 {
        font-weight   : 500;
        font-size     : 20px;
        letter-spacing: 0.26px;
        color         : #000000DE;
        width         : 100%;
        overflow      : hidden;
        white-space   : nowrap;
        text-overflow : ellipsis;
    }

    .a-card .a-card-header img {
        -webkit-transform: translateY(-50%);
        transform        : translateY(-50%);
        border-radius    : 4px;
        position         : absolute;
        height           : 40px;
        width            : 40px;
        left             : 16px;
        top              : 50%;
    }

    .a-card .a-card-header .card-title {
        font-weight  : 400 !important;
        color        : #000000DE;
        font-size    : 24px;
        float        : initial;
        width        : calc(100% - 42px);
        overflow     : hidden;
        white-space  : nowrap;
        text-overflow: ellipsis;
    }

    .a-card .card-status {
        letter-spacing: 0.4px;
        margin-bottom : 12px;
        font-weight   : 500;
        font-size     : 12px;
    }

    .a-card .card-text {
        letter-spacing: 0.25px;
        text-overflow : ellipsis;
        width         : calc(100% - 42px);
        white-space   : nowrap;
        color         : #000000;
        overflow      : hidden;
    }

    .a-card-smd {
        border    : 1px solid transparent;
        text-align: left;
        padding   : 16px;
        overflow: hidden;
        height: 146px;
    }

    .a-card-smd .a-card-header {
        color      : #000000DE;
        font-weight: 400;
        font-size  : 24px;
        padding    : 0;
    }

    .a-card-smd .card-lawfirm {
        text-transform: none;
        letter-spacing: 0.24px;
        color         : #00000099;
        font-weight   : 500;
        font-size     : 14px;
        min-height    : 15px;
        margin        : 0 0 2px;
        width         : 100%;
        overflow      : hidden;
        white-space   : nowrap;
        text-overflow : ellipsis;
    }

    .a-card-smd .card-text {
        letter-spacing: 0.25px;
        margin-top    : 10px;
        margin-bottom: 8px;
        color         : #515365;
        font-size     : 14px;
        overflow: initial;
        text-overflow: initial;
        white-space: normal;
    }

    .a-card-smd .card-another-text {
        -webkit-transition: 0.5s ease;
        transition: 0.5s ease;
        letter-spacing: 0.25px;
        color         : #515365;
        font-size     : 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: calc(100% - 20px);
        margin-top: 8px;
        margin-bottom: 25px;
    }

    .a-card-smd .a-card-header h5 {
        font-size: 24px;
        width: 84%;
    }

    .a-card-smd .a-card-header img {
        -webkit-transform: none;
        transform        : none;
        left             : initial;
        right            : 0;
        top              : 0;
    }

    .a-card-smd .card-body {
        position: relative;
        padding: 0;
    }

    .a-card-smd .table-con {
        height: 182px;
        overflow-y: auto;
    }

    .a-card-smd table tr {
        border: 0;
    }

    .a-card-smd table tr .header-label {
        text-transform: uppercase;
        color         : #515365;
        font-weight   : 400;
    }

    .a-card-smd table tr td {
        font-weight: 500;
        color      : #141517;
        font-size  : 12px;
        padding    : 7px;
    }

    .a-card-smd .card-body img {
        position: relative;
        height: 12px;
        width: 12px;
        right: initial;
        top: -1px;
    }

    .a-card-smd .a-card-toggle {
        -webkit-transition: transform .2s ease;
        transition        : transform .2s ease;
        position          : absolute;
        color             : #515365;
        border            : 0;
        background        : transparent;
        font-size         : 16px;
        top               : 82px;
        right             : 0;
    }

   .a-card-smd .a-card-flag {
        -webkit-transition: transform .2s ease;
        transition        : transform .2s ease;
        position          : absolute;
        color             : #515365;
        border            : 0;
        background        : transparent;
        font-size         : 16px;
        top               : 3px;
        right             : 0;
    }

    .a-card-smd .a-card-toggle:active,
    .a-card-smd .a-card-toggle:focus {
        outline: none;
    }

    .a-card-smd .a-card-toggle:hover {
        opacity: 0.8;
    }

    .a-card-smd.toggled {
        background   : #EAF0FCFC 0% 0% no-repeat padding-box;
        border-color : #2D599E;
        height: 360px;
        z-index: 2;
    }

    /* .a-card-smd::before {
        border: 1px solid transparent;
        -webkit-transition: .5s ease;
        transition: .5s ease;
        border-radius: 5px;
        position:absolute;
        content: "";
        height: 0px;
        width: 100%;
        z-index: 0;
        right: 0;
        left: 0;
        top: 0;
    }

    .a-card-smd.toggled::before {
        border-color : #2D599E;
        height: 100%;
    } */

    .a-card-smd.toggled .card-body {
        background  : #EAF0FCFC 0% 0% no-repeat padding-box;
        display     : block;
    }

    .a-card-smd.toggled .a-card-toggle {
        -webkit-transform: rotate(180deg);
        transform        : rotate(180deg);
        color            : #2D599E;
    }

    .a-card-sm {
        border    : 1px solid transparent;
        text-align: left;
        padding   : 16px;
        overflow: hidden;
        height: 106px;
        /* height    : auto; */
    }

    .a-card-sm .a-card-header {
        color      : #000000DE;
        font-weight: 400;
        font-size  : 24px;
        padding    : 0;
    }

    .a-card-sm .card-lawfirm {
        text-transform: uppercase;
        letter-spacing: 0.24px;
        color         : #2D599E;
        font-weight   : 400;
        font-size     : 14px;
        min-height    : 15px;
        margin        : 0;
        width         : calc(100% - 42px);
        overflow      : hidden;
        white-space   : nowrap;
        text-overflow : ellipsis;
    }

    .a-card-sm .card-text {
        letter-spacing: 0.25px;
        margin-top    : 10px;
        color         : #515365;
        font-size     : 14px;
    }

    .a-card-sm .a-card-header img {
        -webkit-transform: none;
        transform        : none;
        left             : initial;
        right            : 0;
        top              : 0;
    }

    .a-card-sm .card-body {
        position: relative;
        z-index: -2;
        padding: 0;
    }

    .a-card-sm table tr {
        border: 0;
    }

    .a-card-sm table tr .header-label {
        text-transform: uppercase;
        color         : #515365;
        font-weight   : 400;
    }

    .a-card-sm table tr td {
        font-weight: 500;
        color      : #141517;
        font-size  : 12px;
        padding    : 7px;
    }

    .a-card-sm .a-card-toggle {
        -webkit-transition: transform .2s ease;
        transition        : transform .2s ease;
        position          : absolute;
        color             : #515365;
        border            : 0;
        background        : transparent;
        font-size         : 16px;
        top               : 48px;
        right             : 0;
    }

    .a-card-sm .a-card-toggle:active,
    .a-card-sm .a-card-toggle:focus {
        outline: none;
    }

    .a-card-sm .a-card-toggle:hover {
        opacity: 0.8;
    }

    .a-card-sm.toggled {
        background   : #EAF0FCFC 0% 0% no-repeat padding-box;
        height: 259px;
        z-index: 2;
    }

    .a-card-sm::before {
        border: 1px solid transparent;
        -webkit-transition: .5s ease;
        transition: .5s ease;
        border-radius: 5px;
        position:absolute;
        content: "";
        height: 0px;
        width: 0px;
        z-index: 0;
        left: 0;
        top: 0;
    }

    .a-card-sm.toggled::before {
        border-color : #2D599E;
        width: 100.15%;
        height: 100.19%;
    }

    .a-card-sm.toggled .card-body {
        background  : #EAF0FCFC 0% 0% no-repeat padding-box;
        display     : block;
    }

    .a-card-sm.toggled .a-card-toggle {
        -webkit-transform: rotate(180deg);
        transform        : rotate(180deg);
        color            : #2D599E;
    }

    .a-card .hovered-options {
        -webkit-transition: .5s ease;
        transition: .5s ease;
        background-color: transparent;
        border: 1px solid transparent;
        border-radius: 4px;
        position: absolute;
        overflow: hidden;
        max-height: 0;
        width: 100%;
        opacity: 0;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;
    }

    .a-card .hovered-option-con {
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        position: absolute;
        text-align: center;
        width: 100%;
        left: 50%;
        top: 50%;
    }

    .a-card .hovered-option-con .actions {
        -webkit-transition: opacity .5s ease;
        transition: opacity .5s ease;
        display: inline-block;
        margin: 0 -1.7%;
        opacity: 1;
    }

    .a-card .hovered-option-con .actions:hover {
        opacity: 0.8;
    }

    .a-card .hovered-options.hovered {
        background-color: rgba(45, 88, 158, 0.171);
        border-color: #2D599E;
        max-height: 100%;
        opacity: 1;
    }

    /*
    *   ADMIN VARIATIONS
    */
    .gui-admin .a-card {
        width: 300px;
    }

    /* .gui-admin .a-card-smd.toggled::before {
        border-color : #601848;
        height: 100%;
    } */

    .gui-admin .a-card-smd.toggled {
        border-color : #601848;
    }

    .gui-admin .a-card-smd.toggled {
        background  : #f9f6f8 0% 0% no-repeat padding-box;
    }

    .gui-admin .a-card-smd.toggled .card-body {
        background  : #f9f6f8 0% 0% no-repeat padding-box;
        display     : block;
    }

    .gui-admin .a-card-smd.toggled .a-card-toggle {
        color: #601848;
    }
    
    .gui-admin .a-card-smd .card-text {
        color: #C04848;
    }

    .gui-admin .a-card-smd.toggled .card-another-text {
        color: #480048;
    }

    .gui-admin .a-card .hovered-options.hovered {
        border-color: #480048;
        background-color: #60184817;
    }

    .a-card-invalid {
        background: #F6D1D1 0% 0% no-repeat padding-box;
        border: 1px solid #DC1A1A;
        border-radius: 4px;
        opacity: 1;
    }
    .ellipsify{
        width        : 255px !important;
        overflow     : hidden !important;
        text-overflow: ellipsis !important;
    }
</style>