<template>
	<div class="dropdown d-inline-block">
		<a 
			href="javascript:void(0);"
			id="sortDropdown"
			data-toggle="dropdown"
			aria-haspopup="true"
			aria-expanded="false"
		>
			<ar-button 
				class="position-relative font-weight-bold pl-3 a-btn-text"
				style="font-size: 12px; text-align:center; padding-left: 12px !important; padding-right: 12px; padding-top: 3px; padding-bottom: 3px; margin-top: 2px;">
				<img class="position-relative mr-1" style="top: -2px;" :src="icon" alt="backCase"> {{ prefix }} {{ options.find(option => option.value === value).label }}
			</ar-button>
		</a>
		<div class="dropdown-menu" aria-labelledby="sortDropdown">
			<a
				v-for="option in options"
				:key="option.value" 
				class="dropdown-item"
				href="javascript:void(0);"
				@click="$emit('change', option.value)"
			>
				{{ option.label }}
			</a>
		</div>
	</div>
</template>

<script>
import ArButton from '@/components/layouts/Button';

export default {
	components: {
		ArButton
	},
	model: {
		prop: 'value',
		event: 'change'
	},
	props: {
		options: {
			type: [Object, Array],
			required: true
		},
		icon: {
			type: String,
			default: '/static/svg/defendants/Sort.svg'
		},
		prefix: {
			type: String,
			default: 'Sort By -'
		},
		value: {
			type: String,
			default: ''
		}
	}
}
</script>