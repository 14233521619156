<template>
    <aside class="sidebar-light-primary a-sidebar a-sidebar-filter pt-4"
        :class="[a_class, {'with-weekly-quota': showWeeklyQuotaTracker }]"
        :style="a_style"
        @mouseover="$emit('mouseover')"
        @mouseleave="$emit('mouseleave')"
    >
        <!-- <h3 class="a-sidebar-header pl-4 pr-4 pt-4 pb-2 d-inline-block">
            Filters
        </h3> -->

        <component :is="currentFilterDrawer"></component>
    </aside>
</template>

<script>
import AdminDrawer from '@/components/layouts/filterDrawer/admin'
import CmDrawer from '@/components/layouts/filterDrawer/cm'
import VerDrawer from '@/components/layouts/filterDrawer/ver'
export default {
    name:"ar-filter-drawer",
    props:{
        value:{
            type:String,
            default:''
        },
        statuses: {
            type: Array,
            default: () => []
        },
        platforms: {
            type: Array,
            default:() => []
        },
        a_class: {
            type: String,
            default: ''
        },
        a_style: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            currentFilterDrawer: null
        }
    },
    computed: {
        isWeeklyQuotaTrackerRestricted() {
            return this.$featureAccess.isRestricted('v-enable-weekly-quota-tracker')
        },
        showWeeklyQuotaTracker() {
            return !this.isWeeklyQuotaTrackerRestricted && ['listing', 'search'].includes(this.$route.name)
        },
    },
    mounted(){
        this.initializeCurrentFilterDrawer()
    },
    methods: {
        initializeCurrentFilterDrawer() {
            this.currentFilterDrawer = this.$route.path.includes('/admin') 
                ? AdminDrawer
                : this.$route.path.includes('/lawfirm')
                    ? CmDrawer
                    : VerDrawer
        }
    },
    watch: {
        '$route.name': {
            handler() {
                this.initializeCurrentFilterDrawer()
            },
            deep:true
        },
    },
}
</script>

<style>
    .btn-research-status {
        color: #515365;
        background-color: #ffffff;
        border-color: #ffffff;
        box-shadow: none;
        text-align: left;
        font-size: 12px;
        letter-spacing: 0.24px;
        font-weight: 400 !important;
        -webkit-transition: all .3s ease;
        transition: all .3s ease;
        height: 36px;
        line-height: 22px;
    }

    .btn-research-status:hover {
        color: #383A3D;
        font-weight: 700 !important;
        background-color: #D7DEEA;
        border-color: #D7DEEA;
    }

    .btn-research-status:not(:disabled):not(.disabled):active, .btn-research-status:not(:disabled):not(.disabled).active, .show > .btn-research-status.dropdown-toggle {
        color: #203154;
        font-weight: 500 !important;
        background-color: #D7DEEA;
        border-color: #D7DEEA;
    }

    .btn-research-status-icon {
        height: 16px;
        width: 16px;
        line-height: 18px;
        display: inline-block;
        border-radius: 3px;
        text-align: center;
        font-weight: 500;
        position: relative;
        top: 1px;
    }

    .btn-research-status-icon.color-1 {
        background: #a6adbb;
        color: #636f87;
    }

    .btn-research-status-icon.color-2 {
        background: #99a9c9;
        color: #768bb7;
    }

    .btn-research-status-icon.color-3 {
        background: #b9bac1;
        color: #6e707f;
    }

    .btn-research-status-icon.color-4 {
        background: #b1d5f1;
        color: #5ba8e1;
    }

    .btn-research-status-icon.color-5 {
        background: #a9ddda;
        color: #4db9b1;
    }

    .btn-research-status-icon.color-6 {
        background: #f1b1b3;
        color: #e36164;
    }

    .btn-research-status-icon.color-7 {
        background: #ffbca7;
        color: #ff7145;
    }

    .btn-research-status-icon.color-8 {
        background: #9abbe3;
        color: #5c99dd;
    }
</style>

<style scoped>
/*
*   SIDEBAR
*/
.a-sidebar {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 6px #00000017;
    height: 100vh;
    text-align: left;
    position: fixed;
    width:256px;
    opacity: 1;
}

.a-sidebar.with-weekly-quota {
    top: 0;
    padding-top: 170px !important;
}

.a-sidebar .sidebar {
    height: calc(100vh - 93px);
}

.a-sidebar .brand-text {
    letter-spacing: 3.6px;
    color: #3E88DA;
}

.a-sidebar .brand-link .brand-image {
    margin-left: initial;
}

.a-sidebar .sidebar {
    padding:0;
    overflow-y: hidden;
}

.a-sidebar .nav-item .nav-link {
    border-left: 5px solid transparent;
    transition: color 0.2s ease;
}

.a-sidebar .nav-item:hover .nav-link {
    background: #EAF0FCFC !important;
    color: #3E88DA !important;
    border-color: #0F9AEE;
}

.a-sidebar .nav-item .nav-icon {
    margin-right: 13px;
}

.a-sidebar .nav-item:hover .nav-icon {
    color: #0F9AEE;
}

.a-sidebar .nav-link {
    padding-bottom: 13px;
    padding-top: 13px;
    color: #383A3D;
    border-radius: 0;
    font-weight: 500;
    font-size: 16px;
}

.a-sidebar .a-nav-sub .nav-link {
    padding-left: 60px;
    font-weight: 400;
    font-size: 14px;
}

.a-sidebar-header {
    color: #515365;
    font-weight: 500;
    font-size: 24px;
}

.a-sidebar-filter {
    -webkit-transition: top .5s ease, margin .5s ease;
    transition: top .5s ease, margin .5s ease;
    box-shadow: none;
    width: 323px;
    top: 57px;
    right: 0;
}
</style>
