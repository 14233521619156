<script>
export default {
    name: 'feature-access-list',
    data() {
        return {
            isClicked_Sub: false,
            isClicked_Sub_second: false,
            selectedIndex: Number,
            selectedFeature_id: null,
            sub_features_Items: Array,
            selectedFeature: [],
            update_new_name: null,
            parent_val: '',
            is_available: null,
            sub_features: null,
            gui_options: [{
                label: 'Admin',
                value: 'admin'
                }, {
                label: 'Verification',
                value: 'verification'
                }, {
                label: 'Case Management',
                value: 'case management'
            }],

            active_status_options: [{
                label: 'Active',
                value: 1,
            }, {
                label: 'Inactive',
                value: 0,
            }],
            updated_vmodel_gui: '',
            updated_vmodel_name: "",
            updated_vmodel_description: "",
            updated_vmodel_parent_id: '',
            updated_vmodel_page_name: '',
            updated_vmodel_class_name: '',
            updated_vmodel_type: '',
            updated_vmodel_is_active: 1,
            suggested_class_name: null,
            gui: '',
            type: '',
            name: '',
            parentFeatures: null,
            confirm_text: null,
            requiredField: {
                isRequired: false,
                field: '',
                type: ''
            },
            isUpdating: false,
        }
    },
    components: {
        ArButton: () => import('../layouts/Button'),
        ArTextfield: () => import('../layouts/Textfield'),
        ArDialog: () => import('../layouts/Dialog'),
        ArSelect: () => import('../layouts/Select'),
        ArCheckbox: () => import('../layouts/Checkboxes')
    },
    computed: {
        parent() {
            return this.$parent.$root.$children[0].$children.filter(child => child.$vnode.componentOptions.tag == "ar-master")[0];
        },
        is_inputted_successfully() {
            let is_inputted = false
            if(this.updated_vmodel_gui == '' || this.updated_vmodel_gui == null
                || this.updated_vmodel_name == '' || this.updated_vmodel_name == null
                || this.updated_vmodel_description == '' || this.updated_vmodel_description == null
                || this.updated_vmodel_class_name == '' || this.updated_vmodel_class_name == null
                || this.updated_vmodel_type == '' || this.updated_vmodel_type == null
                || this.updated_vmodel_page_name == '' || this.updated_vmodel_page_name == null
                || this.updated_vmodel_is_active.toString() == '' || this.updated_vmodel_is_active == null
            ){
                is_inputted = false
            }
            else { is_inputted = true }
            return is_inputted
        },
        automated_class_name() {
            let class_name = this.selectedFeature.class_name
            if (this.gui !== '' && this.type.toLowerCase() !== '' && this.name.toLowerCase() !== '') {
                class_name = this.gui + '-' + this.type.toLowerCase() + '-' + this.name.toLowerCase()
            }
            else {
                class_name = this.selectedFeature.class_name
            }
            return class_name
        },
        checkParent_id() {
            let parent_id = this.updated_vmodel_parent_id
            if(parent_id == null || parent_id == '') {
                parent_id = ''
            }
            return parent_id
        },
        filter_parentFeature() {
            let all_features_name = this.all_features_name.filter(feature => feature.value !== this.selectedFeature_id)
            return all_features_name
        },
        fieldTypes() {
            return [
                { label: 'Select Type', value: '' },
                { label: 'Evidence', value: 'evidence' },
                { label: 'Data', value: 'data' }
            ];
        },
        requiredFields() {
            const { type } = this.requiredField;

            if (type === '') {
                return [
                    { label: 'Select Field', value: ''}
                ];
            }

            if (type === 'evidence') {
                return [
                    { label: 'Select Field', value: '' },
                    { label: 'Preview', value: 'preview', type: 'evidence' },
                    { label: 'LF Review', value: 'lf review', type: 'evidence' },
                    { label: 'Product', value: 'product', type: 'evidence' },
                    { label: 'Store', value: 'store', type: 'evidence' }
                ];
            }

            return [
                { label: 'Select Field', value: ''},
                { label: 'Claim Type', value: 'claim_type', type: 'data' },
                { label: 'Country', value: 'country_name', type: 'data' },
                { label: 'Main Account' , value: 'seller_account_id' },
                { label: 'Infringement Reasons', value: 'reasons_list' },
                { label: 'Shipping Location', value: 'shipping_location', type: 'data' },
                { label: 'Item Location', value: 'listing_location', type: 'data' },
                { label: 'Date Captured', value: 'date_captured', type: 'data' },
                { label: 'Purchase Type', value: 'purchase_type', type: 'data' },
            ];
        }
    },
    props: {
        features: {
            type: Array,
            default: () => ([])
        },
        all_features: {
            type: Array,
            default: () => ([])
        },
        getCurrentFeature: {
            type: Function,
            default: function() {}
        },
        current_selected_feature: {
            type: Object,
            default: () => ({})
        },
        all_features_name: {
            type: Array,
            default: () => ([])
        },
        status: {
            type: String,
            default: ''
        }
    },
    methods: {
        // checkAvailability(vmodel_name) {
        //     if(vmodel_name == 'update_new_name'){
        //         if (this.update_new_name == null) {
        //             this.is_available = null
        //             return
        //         }
        //         else if (this.update_new_name == this.selectedFeature.class_name){
        //             this.parent.showSnackbar('No changes detected', false, 'info', 'fas fa-info-circle');
        //             console.log('no changes detected')
        //         }
        //         else {
        //             this.parent.showSnackbar('Checking...', true);
        //             for (let i=0; i < this.all_features.length; i++) {
        //                 if (this.update_new_name?.toLowerCase() == this.all_features[i].class_name?.toLowerCase()) {
        //                     this.parent.showSnackbar('Class Name is not available', false, 'invalid', "fas fa-times-circle");
        //                     this.is_available = false 
        //                     return
        //                 } else {
        //                     this.parent.showSnackbar('Class Name is Available', false, 'success', 'fas fa-check-circle');
        //                     this.hideEditCheckModal()
        //                     this.showEditFeatureModal()
        //                     this.is_available = true
        //                 }
        //             }
        //         }
        //     }
        // },
        set_parentFeature_method(data) {
            this.parentFeatures = data
        },
        hide_sub(index) {
            this.selectedIndex = this.selectedIndex == index ? -1 : index
        },
        check_class_name(class_name) {
            this.parent.showSnackbar('Checking...', true);
            return new Promise(resolve => {
                const result = this.all_features.some(a => a.class_name?.toLowerCase() == class_name?.toLowerCase())
                const message = result ? 'Class Name is not available': 'Class Name is available'

                this.parent.showSnackbar(message, false, result ? 'invalid' : 'success', `fas fa-${result ? 'times' : 'check'}-circle`)

                resolve(result)
            })
        },
        async update() {
            this.isUpdating = true;

            if (!this.is_inputted_successfully) {
                this.isUpdating = false;

                this.parent.showSnackbar('Invalid, please input all textbox!', false, 'invalid', 'fas fa-times-circle');
                return;
            }

            const id = this.current_selected_feature.id;
            const classname = this.updated_vmodel_class_name.toLowerCase();

            const isClassnameTaken = this.all_features.some(feature => id !== feature.id && feature.class_name === classname);

            if (isClassnameTaken) {
                this.parent.showSnackbar('Class Name already exists!', false, 'invalid', 'fas fa-times-circle');
                this.isUpdating = false;
                return;
            }

            if (this.updated_vmodel_parent_id == undefined || this.updated_vmodel_parent_id == 0 ) {
                this.updated_vmodel_parent_id = null;
            }

            const body = {
                parent_id   : this.updated_vmodel_parent_id,
                gui         : this.updated_vmodel_gui,
                name        : this.updated_vmodel_name,
                description : this.updated_vmodel_description,
                page_name   : this.updated_vmodel_page_name,
                type        : this.updated_vmodel_type,
                is_active   : this.updated_vmodel_is_active,
                class_name  : classname,
            };

            const { field, type, isRequired } = this.requiredField;

            if (isRequired) {
                if (type === '' || field === '') {
                    this.parent.showSnackbar('Please select the type and field!', false, 'invalid', 'fas fa-times-circle');
                    this.isUpdating = false;

                    return;
                }

                body.required_field = JSON.stringify({ field, type });
            }

            const headers = this.$session.get('admin_headers');

            const { status } = await this.$http.patch(`api/features/${id}`, body, headers);

            this.isUpdating = false;

            if (status !== 200) {
                this.$emit('isUpdated', false);
                this.parent.showSnackbar('Something went wrong while updating feature. Please contact System Admin.', false, 'invalid', 'fas fa-times-circle');
                return;
            }

            this.$emit('isUpdated', true);
            this.parent.showSnackbar('Successfully updated feature!', false, 'success', 'fas fa-check-circle');
            // this.hideEditFeatureModal();

            return;
        },
        initializeVmodel() {
            
            if(this.selectedFeature.parent_id == null || this.selectedFeature.parent_id == 0){
                this.updated_vmodel_parent_id = null
            }
            else {
                this.updated_vmodel_parent_id = this.selectedFeature.parent_id
            }
            this.updated_vmodel_gui = this.selectedFeature.gui
            this.updated_vmodel_description = this.selectedFeature.description
            this.updated_vmodel_page_name = this.selectedFeature.page_name
            this.updated_vmodel_class_name = this.selectedFeature.class_name
            this.updated_vmodel_type = this.selectedFeature.type
            this.updated_vmodel_name = this.selectedFeature.name
            this.updated_vmodel_is_active = this.selectedFeature.is_active
        },
        initializeVmodel_no_data(){
            this.updated_vmodel_gui = ''

            this.updated_vmodel_parent_id = null

            this.updated_vmodel_description = ''
            this.updated_vmodel_page_name = ''
            this.updated_vmodel_class_name = ''
            this.updated_vmodel_type = ''
            this.updated_vmodel_name = ''
            this.updated_vmodel_is_active = 1
        },
        resetConfirm_text() {
            this.confirm_text = ''
        },
        showEditCheckModal() {
            this.update_new_name = this.selectedFeature.class_name
            $('#update-check-modal').modal('show')
        },
        hideEditCheckModal() {
            $('#update-check-modal').modal('hide')
        },
        showEditFeatureModal() {
            this.initializeVmodel()
            $('#edit-feature-modal').modal('show')
        },
        hideEditFeatureModal() {
            this.initializeVmodel_no_data()
            $('#edit-feature-modal').modal('hide')
        },
        showDeleteConfirmationModal() {
            $('#delete-feature-modal').modal('show')
        },
        hideDeleteConfirmationModal() {
            this.resetConfirm_text()
            $('#delete-feature-modal').modal('hide')
        },
        check_sub_features(feature_id) {
            let all_features = this.all_features.filter(a => a.parent_id == feature_id)
                .map(a => ({
                    id: a.id,
                    gui: a.gui,
                    name: a.name,
                    description: a.description,
                    page_name: a.page_name,
                    type: a.type,
                    class_name: a.class_name,
                    parent_id: a.parent_id,
                }))
            return all_features
        },
        confirmDeletion() {
            const headers = this.$session.get('admin_headers')
            this.$http.delete(`api/features/${this.selectedFeature_id}`,headers)
            .then( response => {
                if(response.status == 204) {
                    this.$emit('isDeleted', true)
                    this.parent.showSnackbar(`Successfully Deleted Feature ${this.selectedFeature.class_name}`, false, 'success', 'fas fa-check-circle')
                    this.hideDeleteConfirmationModal()
                }else {
                    this.$emit('isDeleted', false)
                    this.parent.showSnackbar('Deleting Feature Failed', false, 'invalid', 'fas fa-times-circle')
                }
            }).catch( error => {
                console.log(error)
            })
        }
    },
    watch: {
        update_new_name(val) {
            if(val == ''){
                this.update_new_name = null
            }
            else {
                this.updated_vmodel_class_name = val
                this.is_available = null
            }
        },
        updated_vmodel_gui(val) {
            switch (val) {
            case 'admin': {
                this.gui = 'a'
                break
            }
            case 'verification': {
                this.gui = 'v'
                break
            }
            case 'case management': {
                this.gui = 'cm'
                break
            }
            default: {
                this.gui = ''
            }
            }
        },
        updated_vmodel_type(val) {
            if(val !== '') {
                this.type = val.trim().replace(/\s+/g, '-')
            }
            else {
                this.type = ''
            }
        },
        updated_vmodel_name(val) {
            if(val !== '') {
                this.name = val.trim().replace(/\s+/g, '-')
            }
            else {
                this.name = ''
            }
        },
        updated_vmodel_is_active(val) {
            this.updated_vmodel_is_active = parseInt(val)
        },
        automated_class_name(val) {
            if(this.selectedFeature.gui !== this.updated_vmodel_gui
                || this.selectedFeature.type !== this.updated_vmodel_type
                || this.selectedFeature.name !== this.updated_vmodel_name
            ){
                this.updated_vmodel_class_name = val
            }
            else { this.suggested_class_name = val }
        },
        all_features() {
            this.check_sub_features(this.selectedFeature_id)
        },
        status() {
            this.selectedFeature_id = null
            this.selectedIndex = -1
        },
        selectedFeature(feature) {
            const requiredField = feature.required_field ?? {};
            const hasValues     = Object.values(requiredField).length !== 0;

            this.requiredField = {
                isRequired : hasValues,
                field      : hasValues ? requiredField.field : '',
                type       : hasValues ? requiredField.type : ''
            };

            return;
        },
        requiredField: {
            handler(value) {
                const { isRequired, type, field } = value;

                if (!isRequired) {
                    this.requiredField.type = '';
                    this.requiredField.field = '';
                    return;
                }

                if (type === '' && field !== '') {
                    this.requiredField.field = '';
                    return;
                }

                return;
            },
            deep: true
        }
    }
}
</script>
<template>
    <div class="ar-feature-list">
        <div v-for="(feature, index) in features"
            :key="feature.key"
            :class="{
                'ver': feature.gui == 'verification',
                'admin': feature.gui == 'admin',
                'cmgmnt': feature.gui == 'case management',
                'dropdownClick': isClicked_Sub == true && selectedFeature_id == feature.id,
                'inactive': feature.is_active == 0,
            }"
            class="ar-feature-list-item"
        >
            <div 
                class="item-upper"
                :class="{'borderToggled': isClicked_Sub == true && selectedIndex == index && feature.hasSub_feature == true}"
            >
                <div class="item-left-side">
                    <div class="item-left-side-title-group">
                        <p class="item-left-side-title mb-0">
                            {{ feature.type }} -
                        </p>
                        <p class="item-left-side-title mb-0">
                            {{ feature.name }}
                        </p>
                        <p class="button-style mb-0 ml-2">
                            {{ feature.class_name}}
                        </p>
                    </div>
                    <p class="item-left-side-description mb-0">
                        {{ feature.description }}
                    </p>
                </div>
                <div
                    class="item-right-side"
                    :class="{'hasSubFeature': check_sub_features(feature.id).length > 0}"
                >
                    <span
                        class="gui-button"
                        :class="{
                            'ver': feature.gui == 'verification',
                            'admin': feature.gui == 'admin',
                            'cmgmnt': feature.gui == 'case management',
                        }"
                    >
                        <p class="gui-button-title">
                            {{ feature.gui }}
                        </p>
                    </span>
                    <!--  -->
                    <template v-if="check_sub_features(feature.id).length > 0">
                        <button 
                            v-tooltip="`Show Sub Feature/s`"
                            class="dropdown-button fas fa-chevron-down"
                            @click="hide_sub(index)"
                            :class="{'isClicked': selectedIndex == index}"
                        ></button>
                    </template>
                    <button
                        class="edit-button"
                        :class="{ 'hasSubFeature': check_sub_features(feature.id).length > 0, 'dropdownClick': isClicked_Sub == true && selectedFeature_id == feature.id }"
                        v-tooltip="`Edit Feature`"
                        
                        @click="
                            selectedIndex = -1;
                            selectedFeature_id = feature.id;
                            selectedFeature = feature;
                            set_parentFeature_method(filter_parentFeature);
                            $emit('getFeatureID', selectedFeature_id);
                            showEditFeatureModal()
                        "
                    >
                    <!-- @click="getCurrentFeature(feature)" -->
                        <img src="/static/svg/feature-management/Status_Update.svg" alt="Edit">
                    </button>
                    <button
                        class="delete-button"
                        :class="{ 'hasSubFeature': check_sub_features(feature.id).length > 0, 'dropdownClick': isClicked_Sub == true && selectedFeature_id == feature.id }"
                        @click="
                            selectedFeature_id = feature.id;
                            selectedFeature = feature;
                            $emit('getFeatureID', selectedFeature_id);
                            showDeleteConfirmationModal();
                        "
                    >
                        <img src="/static/svg/Delete.svg" alt="Delete">
                    </button>
                </div>
            </div>
            <div class="item-lower" v-if="selectedIndex == index">
                <template  >
                    <div v-for="(sub, index2) in check_sub_features(feature.id)" :key="index2" class="sub-feature">
                        <template>
                            <button
                                class="edit-subFeature-button"
                                v-tooltip="`Edit Sub Feature`"
                                @click="
                                    selectedIndex = index;
                                    selectedFeature_id = sub.id;
                                    selectedFeature = sub;
                                    set_parentFeature_method(filter_parentFeature);
                                    $emit('getFeatureID', selectedFeature_id);
                                    showEditFeatureModal();
                                "
                            >
                                <img src="/static/svg/feature-management/Status_Update.svg" alt="">
                            </button>
                            <img src="/static/svg/feature-management/Sub-icon.svg" class="sub-feature-img" alt="">
                            <p class="sub-feature-title">
                                {{ sub.name }}  ({{sub.class_name}})
                            </p>
                            <p class="sub-feature-description">
                                {{ sub.description }}
                            </p>
                        </template>
                    </div>
                </template>
            </div>
        </div>
        <template>
            
            <!-- 
                check availability 
                Update Feature
            -->
            <!-- <ar-dialog 
                id="update-check-modal"
                custom_width="800px"
                large
                :has_button="false"
                :has_footer="false"
            >
                <div class="add-feature-check">
                    <div class="add-feature-left">
                        <p class="edit-title">
                            Update Feature
                        </p>
                        <ar-textfield 
                            label="Class Name"
                            :placeholder ="selectedFeature && selectedFeature.class_name"
                            a_style="width: 300px"
                            v-model="update_new_name"
                            :err_txt="is_available == null ? `This field is required` : `${is_available == true ? `Available` : `Class Name is not Available` }`"
                            :state="is_available == null ? `''` : `${is_available == true ? `''` : `invalid` }`"
                            :isRequired="true"
                            @enter="checkAvailability('update_new_name')"
                        />
                        <div class="check-description">
                            <label class="mb-0 d-block" style="color: #601848; font-size: 16px;">Before Updating,</label>
                            <p style="font-size: 16px;">let us check if the inputted seller already existed in the database.</p>
                        </div>
                        <div class="flex-gap-five">
                            <ar-button
                                @click="checkAvailability('update_new_name')"
                                :disabled="this.update_new_name == null"
                            >
                                Check Availability
                            </ar-button>
                            <ar-button
                                a_class="mr-2"
                                outlined
                                @click="showEditFeatureModal();hideEditCheckModal()"
                            >
                                Cancel
                            </ar-button>
                        </div>
                    </div>
                    <img class="mt-3" src="/static/svg/Database_Photo.svg" alt="Woman standing beside bars bars">
                </div>
            </ar-dialog> -->
        
        
            <!-- Update feature -->
            
            <ar-dialog 
                id="edit-feature-modal"
                custom_width="auto"
                custom_maxwidth="800px"
                a_style="overflow: scroll"
            >
                <!-- other inputs or data here -->
                <div class="edit-modal justify-content-start flex-wrap">
                    <p class="edit-title mb-3">
                        Update Feature
                    </p>
                    <div class="edit-modal-container overflow-auto w-100">
                        <div class="name-container">
                            <div class="edit-textfield" style="width: 98%">
                                <div class="edit-textfield-item" style="width: 50%">
                                    <ar-textfield 
                                        label     ="Class Name"
                                        v-model   ="updated_vmodel_class_name"
                                        :disabled ="true"
                                    />
                                </div>
                                <div class="edit-textfield-item" style="width: 50%">
                                    <ar-select
                                        label                     ="GUI"
                                        :options                  ="gui_options"
                                        v-model                   ="updated_vmodel_gui"
                                        :first_element_as_default ="false"
                                        placeholder               ="Select here"
                                        :isDisable_first_option   ="true"
                                        :val                      ='updated_vmodel_gui'
                                    >
                                        Select GUI Here
                                    </ar-select>
                                </div>
                            </div>
                        </div>
                        <div class="name-container">
                            <div class="edit-textfield" style="width: 98%">
                                <div class="edit-textfield-item" style="width: 50%">
                                    <ar-textfield
                                        label        ="Type"
                                        :placeholder ="current_selected_feature && current_selected_feature.type"
                                        v-model      ="updated_vmodel_type"
                                    />
                                </div>  
                                <div class="edit-textfield-item" style="width: 50%">
                                    <ar-textfield 
                                        label        ="Name"
                                        :placeholder ="current_selected_feature && current_selected_feature.name"
                                        v-model      ="updated_vmodel_name"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="name-container">
                            <div class="edit-textfield" style="width: 98%">
                                <div class="edit-textfield-item" style="width: 50%">
                                    <ar-textfield 
                                        label        ="Page"
                                        :placeholder ="current_selected_feature && current_selected_feature.page_name"
                                        v-model      ="updated_vmodel_page_name"
                                    />
                                </div>
                                <div class="edit-textfield-item" style="width: 50%">
                                    <ar-select
                                        label                     ="Status"
                                        :options                  ="active_status_options"
                                        v-model                   ="updated_vmodel_is_active"
                                        :first_element_as_default ="false"
                                        placeholder               ="Select here"
                                        :isDisable_first_option   ="true"
                                        :val                      ='updated_vmodel_is_active'
                                    >
                                        Select Status here
                                    </ar-select>
                                </div>
                            </div>
                        </div>
                        <div class="name-container">
                            <ar-checkbox
                                id       ="is-required-update"
                                name     ="is-required-update"
                                label    ="Is field required before tagging listings as &quot;Researched&quot;?"
                                @change  ="requiredField.isRequired = !requiredField.isRequired"
                                :checked ="requiredField.isRequired"
                            />
                        </div>
                        <transition name="fade">
                            <div v-if="requiredField.isRequired" class="name-container">
                                <div class="edit-textfield" style="width: 98%">
                                    <div class="edit-textfield-item" style="width: 50%">
                                        <ar-select
                                            label    ="Type"
                                            v-model  ="requiredField.type"
                                            :options ="fieldTypes"
                                            :val     ="requiredField.type"
                                            first_element_as_default
                                        />
                                    </div>
                                    <div class="edit-textfield-item" style="width: 50%">
                                        <ar-select
                                            label     ="Field"
                                            v-model   ="requiredField.field"
                                            :options  ="requiredFields"
                                            :disabled ="requiredField.type === ''"
                                            :val      ="requiredField.field"
                                            first_element_as_default
                                        />
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <div class="name-container">
                            <ar-textfield 
                                class        ="edit-feature-desc"
                                label        ="Description"
                                :placeholder ="current_selected_feature && current_selected_feature.description"
                                v-model      ="updated_vmodel_description"
                                a_type       ="textarea"
                                a_style      ="height: auto !important; resize: none; min-height: 80px; width: 98%"
                            />
                        </div>
                        <div class="name-container">
                            <div class="edit-textfield" style="width: 98%">
                                <ar-select
                                    label                     ="Select Main Feature (if you're adding this as a Sub - Feature)"
                                    :options                  ="parentFeatures"
                                    v-model                   ="updated_vmodel_parent_id"
                                    :first_element_as_default ="false"
                                    placeholder               ="Select here"
                                    :isDisable_first_option   ="false"
                                    :val                      ="updated_vmodel_parent_id"
                                >
                                    Select Feature here
                                </ar-select>
                            </div>
                        </div>
                </div>
                </div>

                <template v-slot:buttons>
                    <ar-button
                        a_class="mr-2"
                        @click="hideEditFeatureModal()"
                        outlined
                    >
                        Cancel
                    </ar-button>

                    <ar-button
                        @click="update()"
                        :disabled="isUpdating"
                    >
                        {{  isUpdating ? 'Updating...' : 'Update Now' }}
                    </ar-button>
                </template>
            </ar-dialog>
        </template>
        <template>
            <ar-dialog id="delete-feature-modal">
                <h4 style="color: #DC3C40;" class="mb-3">Remove Feature <small class="d-block text-capitalize"> {{ `${current_selected_feature?.type} - ${current_selected_feature?.name}` }} </small> <small class="d-block">{{`(${current_selected_feature?.class_name})`}} </small></h4>
                <p class="text-muted delete-feature-desc">Please input <b>Confirm</b> to remove this reason for security reasons.</p>
                <ar-textfield 
                    placeholder         ="Input here"
                    label               ="Type Confirm"
                    type                ="text"
                    a_class             ="text-left"
                    v-model.trim        ="confirm_text"
                />
                <template v-slot:buttons>
                    <ar-button
                        a_class="mr-2"
                        @click="hideDeleteConfirmationModal()"
                        outlined>
                        Cancel
                    </ar-button>
                    <ar-button
                        class="accent"
                        :disabled="confirm_text != 'Confirm'"
                        @click="confirmDeletion()"
                    >
                        Remove Now
                    </ar-button>
                </template>
            </ar-dialog>
        </template>
    </div>
</template>
<style scoped>
/* global */
.button-style {
    padding: 2px 6px 1px;
    border: 1px solid #2D599E;
    display: inline-block;
    border-radius: 4px;

    font-size: 12px;
    /* color:#515365; */
}

/* list */
.ar-feature-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;

    width: 100%;
    height: 100%;
    padding-left: 26px;
    margin-bottom: 10px;
    overflow: scroll;
}
/* list-item */
.ar-feature-list-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 14px;


    padding-right: 26px;
    padding-left: 17px;
    padding-top: 12px;
    padding-bottom: 12px;

    width: calc(100% - 77px);

    background: #FFFFFF;
    border-radius: 4px;
    box-shadow: 0px 1px 2px #00000029;

    margin-left: 60px;
    border: 1px solid transparent;
}
.ar-feature-list-item.dropdownClick {
    background: #FFFFFF29;
    border: 1px solid #C3ADBE;
    transition: .1s;
}
.ar-feature-list-item:hover {
    background: #60184829;
    border: 1px solid #4800481C;
    transition: .1s;
}
.ar-feature-list-item:hover .edit-button {
    visibility: unset;
    transition: .5s;
}
.ar-feature-list-item:hover .edit-button.hasSubFeature {
    display: block;
}

.ar-feature-list-item:hover .delete-button {
    visibility: unset;
    transition: .5s;
}
.ar-feature-list-item:hover .delete-button.hasSubFeature {
    display: block;
}
.ar-feature-list-item:hover .item-right-side.hasSubFeature {
    margin-right: 0px;
}

/* .ar-feature-list-item:hover .button-style {
    border: none;
    background: #2D599E;
    color: #FFFFFF;
} */

.ar-feature-list-item.admin {
    border-left: 4px solid #601848;
}
.ar-feature-list-item.ver {
    border-left: 4px solid #3E88DA;
}
.ar-feature-list-item.cmgmnt {
    border-left: 4px solid #3B5998;
}
.ar-feature-list-item.inactive {
    background: #F2C6C7;
    border-right: 1px solid #DC3C40;
    border-top: 1px solid #DC3C40;
    border-bottom: 1px solid #DC3C40;
}
/* item upper */
.item-upper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    padding-bottom: 14px;

    width: 100%;
    height: auto;
    min-height: 58px;
}
.item-upper.borderToggled {
    border-bottom: 1px solid #7070706E;
}

/* item lower  */
.item-lower {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;

    max-height: 1000px;
    z-index: 1;

    width: 100%;
    transition: ease-in-out .5s;
}

.sub-feature {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;

    position: relative;

    padding-left: 60px;
    
    width: calc(100% - 200px);
}
.sub-feature p {
    margin-bottom: 0;
}
.sub-feature-img {
    position: absolute;
    left: 0;
    padding-left: 40px;
    z-index: -1;
}
.sub-feature-title {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.21px;
    color: #601848;
}
.sub-feature-description {
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.21px;
    color: #53606D;

    word-wrap: break-word;
}

/* left-side */
.item-left-side {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
    width: 80%;
}

.item-left-side-title-group {
    display: flex;
    justify-content: flex-start;
    gap: 3px;
    align-items: center;
}

.item-left-side-title {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.24px;
    color: #515365;
    text-transform: capitalize;
}
.item-left-side-description {
    font-size: 12px;
    font-weight: 400;
    color: #53606D;
    width: 95%;
    word-wrap: break-word;
}
/* end left side */

/* right side */
.item-right-side {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.item-right-side.hasSubFeature {
    margin-right: 40px;
}

/* gui button */
.gui-button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: max-content;
    min-width: 97px;
    height: 24px;

    padding: 4px 16px;

    border-radius: 12px;
    border: none;

    background: #601848;
}
.gui-button p {
    margin-bottom: 0;
    font-size: 12px;
    color: #FFFFFF;
    
}
.gui-button.admin {
    background: #601848;
}
.gui-button.ver {
    background: #3E88DA;
}
.gui-button.cmgmnt {
    background: #3B5998;
}

.gui-button-title {
    text-transform: capitalize;
    font-weight: 500;
}
/* dropdown-button */
.dropdown-button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 24px;
    height: 24px;

    background: #C04848;
    border-radius: 100%;
    border: none;
    outline: none;

    color: #FFFFFF;
    transition: ease-in-out .2s;
}
.dropdown-button.isClicked {
    transform: rotate(180deg);
    transition: ease-in-out .2s;
}

/* edit button */
.edit-button {
    width: 24px;
    height: 24px;
    
    border-radius: 100%;
    border: 1px solid #480048;
    outline: none;

    visibility: hidden;
}
.edit-button.hasSubFeature {
    display: none;
}
.edit-button.dropdownClick {
    display: block;
    visibility: unset;
}

/* delete button */
.delete-button {
    width: 24px;
    height: 24px;
    z-index: 1;
    
    border-radius: 100%;
    border: 1px solid #480048;
    outline: none;

    visibility: hidden;
}
.delete-button img {
    width: 20px;
    height: 20px;
    z-index: -100;
}
.delete-button.hasSubFeature {
    display: none;
}
.delete-button.dropdownClick {
    display: block;
    visibility: unset;
}
/* end right side */

/* ========================================================== */

/* modal */
/* check availability */
.add-feature-check {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-items: center;
  margin-bottom: 30px;
}
.add-feature-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  gap: 17px;
}
.add-feature-left p {
  margin-bottom: 0;
}
.check-description {
  width: 276px;
  height: 70px;
}
/* changed-feature-btn */
.changed-feature-btn {
  position: absolute;
  left: 0;
}
/* Modal */
.edit-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}
.edit-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  width: 650px;
  height: 600px;
  text-align: left;
}
/* edit left */
.edit-upper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 17px;

  width: 100%;
}
.edit-title {
  font-size: 24px;
  font-weight: 500;
  color: #515365;
  margin-bottom: 0;
}
.edit-textfield {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 50px;
  width: 100%;
}
.edit-textfield-item {
  height: 80px;
  margin-bottom: 16px;
}
.edit-feature-desc{
  font-size: 14px;
  color: #383A3D;
  width: 100% !important;
  height: auto;
}
/* update button */
.flex-gap-five {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
/*  */
/* name-container */
.name-container {
  gap: 40px;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;


  width: 100%;
}
/* sub feature button */
.edit-subFeature-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    
    border-radius: 100%;

    border: 1px solid #480048;
    outline: none;

    /* visibility: hidden; */

    position: absolute;
    left: 0;
    top: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
